import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Container, Row } from "react-bootstrap";

//email_account.html
const ManagingEmailAccount = () => {
  return (
    <>
      <CustomMetas 
          metaTitle="Web Mail Hosting | Managing Email Accounts with Hosting Plans"
          metaDesc="Email Setup procedures and how-to. create e-mail accounts, mail Redirects, mail Autoresponders, antivirus filtering, spam mail filter settings"
          metaKeywords="web mail hosting, Web-based productivity, messaging, project-management"
         />
    <Header 
        isDomainPage={false}
        siteTitle="Managing Email Accounts" 
        siteSubTitle="how to setup your email syytem with hosting accounts?"   
      />
      <Container>
      <Row className="border border-warning" style={{margin:"40px 0px"}}>
          <h2>Managing Email Accounts</h2>
        </Row>
        <Row>
          <h3>Managing Mail Redirects</h3>
          <p>You can forward or redirect email from one mailbox to another email address. By creating an email redirect or alias, messages are sent to a different email box without requiring the sender to know the new address. Email can be redirected to an address outside the domain. Use this redirect feature to:</p>
          <ul>
            <li>Temporarily forward mail when the person who owns the mailbox is unavailable. </li>
            <li>Send mail to a new mailbox if a mailbox user is leaving the company. </li>
            <li>Forward mail to a new account, which will eventually replace an old mailbox. 
                  (e.g. someone is changing their name but hasn't had time to inform all correspondents 
                  of the change yet).</li>
          </ul>
          <p>In order to enable and set a redirect for the mail name, follow these steps:</p>
          <ol>
            <li>On the mail name properties page, click  the Redirect icon.</li>
            <li>Select the Redirect checkbox, and in the text box to the right, enter the appropriate 
            address that you wish mail for this mail name to be forwarded to.</li>
            <li>Click OK.</li>
          </ol>
          <p>Once enabled, the Redirects icon on the Mail Names page appears in color.</p>
        </Row>
        <Row>
          <h3>Managing Mail Groups</h3>
          <p>A mail group is a list of several email accounts 
							that are grouped together under one email address. 
							This feature enables sending one message to multiple 
							recipients at once. For example, if you want to send 
							the same message to five people in the technical 
							support department, you can create a "Support" email 
							group that includes the individual email addresses 
							for all five staff members. When someone sends a 
							message to mail group "Support", he/she only types 
							and sends one message, but copies of the message go 
							to all five individuals. The sender does not need to 
							know the addresses for all five individuals, just 
							the group name. Essentially, mail groups help save 
							time and effort.</p>
          <p>In order to enable and set up a mail group for 
							the mail name, follow these steps:</p>
          <ol>
              <li>On the mail name properties page, click the Mail Group icon.</li>
              <li>Before enabling the mail group, you need to add at least one mail group member. 
                Click Add New Member.</li>
              <li>Enter the desired external e-mail address into the E-mail input field and/or
                  select one or more of the listed mail name accounts using checkboxes, and click OK.
                  <br /><strong>NOTE: </strong><em>Group members can consist of either external mail addresses (those not  belonging to this domain) or accounts, 
                which exist within the domain.</em>
              </li>
              <li>The selected addresses will appear in the list of Mail group members on the Mail Name Properties page.</li>
              <li>To delete one or several group members, select the corresponding checkbox and 
                Click Remove Selected.</li>
            </ol>   
            <p>Once enabled, the mail group icon on the Mail 
							Names page appears in color.</p>
            <p>Clicking on the Groups button you will access the 
            Mail Groups Management page.</p>
            <p>All mail groups created for the domain are 
            displayed on that page and two lists are presented: 
            the list of mail groups you are currently subscribed 
            to is located on the right side, and the list of 
            available mail groups is on the left. </p>
            <ul>
                <li>If you wish to subscribe to a new mail 
                group, select the desired group from the 
                list of available mail groups, and click 
                Add&gt;&gt;.</li>
                <li>If you wish to unsubscribe from a mail 
                group, select it in the right side list, and 
                click &lt;&lt;Remove.</li>
                <li>Click Up Level to return to the Mail 
                Name properties page.</li>
            </ul>
        </Row>
        <Row>
          <h3>Managing Mail Autoresponders</h3>
          <p>A mail autoresponder is an automatic reply that 
							is sent out from a given mail name when incoming 
							mail is received at that address. Autoresponders can 
							include both a text message and attached files. This 
							mail function is often used on mail accounts for 
							individuals who need an automated response because 
							they are away, or are unable to check their mail for 
							any number of reasons. In the autoresponders 
							management section you can upload and include 
							attachment files for your autoresponders, enable the 
							autoresponder function for a given mail name, and 
							access the list of autoresponders.</p>
          <p><strong>Attachment files repository</strong></p>
          <p>For the autoresponder feature you have the 
								option to include file attachments. To include a 
								file to be selectable within the set up of 
								autoresponders for the given mail name, use the 
								Attachment Files icon available from the 
								Autoresponders management page. The Attachment 
								files repository page opens. It allows you to 
								upload files and remove them.</p>
          <p>To upload a file, specify the path and 
								filename in the File name field, and click Send 
								File. The attachment will then appear in the 
								Repository.</p>
          <p>These files will be available for any 
								autoresponders that are set up for the given 
								mail name. To delete one or more files, select 
								the checkboxes related to the files you wish to 
								remove, and click Remove Selected button.
							</p>
          <p>These files will be available for any 
								autoresponders that are set up for the given 
								mail name. To delete one or more files, select 
								the checkboxes related to the files you wish to 
								remove, and click Remove Selected button.
							</p>
          <p>In order to enable and set up a mail autoresponder for the given mail name, 
              <strong>follow these steps:</strong></p>
          <ol>
              <li>On the mail name properties page, click 
              the Autoresponders icon. Autoresponders 
              management page will open.</li>
              <li>Click Add New Autoresponder. The 
              autoresponder creation/editing page will 
              open.</li>
              <li>Enter the name into the Autoresponder 
              name field.</li>
              <li>You can enable forwarding of incoming 
              messages that match the autoresponder 
              conditions. To do this, specify an e-mail 
              address in the appropriate input box.</li>
              <li>In the Conditions group, you can 
              determine whether a given autoresponder 
              should respond when specific words are found 
              in the subject line or body of the incoming 
              e-mail message, or you can set it to respond 
              to any e-mail messages directed at this mail 
              name.</li>
              <li>By default, the subject line of incoming 
              e-mail will be inserted into the automated 
              reply. To use a custom subject, type it into 
              the Reply subject input box.</li>
              <li>In the Return address field, you can 
              specify the return address that will be set 
              up in the autoresponder message. This is 
              done for the messages not to be directed to 
              the autoresponder itself, when users use the 
              "Reply to the message" function in their 
              mail client software.</li>
              <li>You have an option to specify the 
              autoresponder message format (plain text or 
              HTML) and encoding (UTF-8 is recommended). 
              If you use HTML tags in your auto-reply 
              message, you should select the HTML option.</li>
              <li>You can enter text to be included into 
              the autoresponder in the Reply with text 
              field.</li>
              <li>Using the Add New Attachment button, you 
              can attach files to be included in the 
              autoresponder. These files must be uploaded 
              into the Repository on the Mail Names 
              Properties page. Select the uploaded file 
              from the Attach files list, and use the Add 
              New Attachment button to attach the file to 
              the autoresponder. To remove an attached 
              file, select the corresponding checkbox, and 
              click Remove Selected.</li>
              <li>You can limit the number of automated 
              responses per day to the same e-mail 
              address. To do this, enter the desired 
              number in the respective input box. The 
              default setting is to respond not more than 
              10 times in one day to the same e-mail 
              address.</li>
              <li>You can define the number of unique 
              addresses that the autoresponder will 
              remember. Enter the desired number in the 
              Store up to: field. This memory enables the 
              system to control response frequency. In the 
              event of extremely high mail volume, to 
              protect server performance, you can limit 
              the address memory of the system database.</li>
              <li>Click OK to submit all changes.</li>
              <li>Click the Enable buton to enable the 
              autoresponder service.</li>
            </ol>
        </Row>
        <Row>
        <h3>Managing the spam mail filter settings</h3>
        <p>Plesk allows for setting up and using black lists and 
          white lists for filtering mail at the server level as 
          well as at the user level.</p>
          <p>The user level spam filter functionality is available 
          for each specific mail name configured as a mailbox. 
          That means that the Mailbox functionality should be 
          activated for the selected mail name.</p>
          <p>If the spam filtering functionality is enabled for 
          users by the Administrator, it should first be 
          activated. To do that:</p>
          <ol>
              <li>Go to the selected Mailbox management page 
              (select the mail name and click the Mailbox 
              icon, Tools group);</li>
              <li>Check the Enable spam filtering checkbox;</li>
              <li>Click OK to save changes.</li>
          </ol>
          <p>You will see the Spam Filter icon become active 
						(displayed in color), meaning that the spam filtering 
						functionality is now available for this mail name. If 
						the spam filtering functionality was not enabled for the 
						users by the Administrator, the Enable spam filtering 
						checkbox at the Mailbox management page will be inactive 
						and the Spam Filter icon will also be inactive 
						(displayed in gray).</p>
						<p>Click on the Spam Filter icon to access the Spam 
						filter configuration page, where you can set the 
						filtering rules for the selected mail name.</p>
						<p>If the Administrator has set up and activated mail 
						filter at the server level, all the incoming mail will 
						be processed with it before it reaches the users’ 
						mailboxes. You can choose to use or, on the contrary, 
						not use the server wide settings for your mail. If you 
						decide not to use the server wide settings, those will 
						be disregarded and your mail will be processed only 
						according to the configuration you set at the user 
						level.</p>
						
							<ol>
								<li>To use (not use) the server wide mail 
								filtering settings, check (uncheck) the Use 
								server wide settings checkbox;</li>
								<li>Click Set to save the changes.</li>
							</ol>
						
						<p>In order to recognize a mail message as spam it needs 
						to score a certain amount of hits. The hits are scored 
						according to the internal SpamAssassin settings and 
						based on the contents of the mail messages and its 
						subject. You can change the sensitivity of the spam 
						filter by varying the amount of hits required for 
						marking a message as spam. The more hits are required 
						the less sensitive the filter is, and vice versa – the 
						less hits are required the more sensitive the filter is.</p>
						
							<ol>
								<li>The default amount of hits is set to 7. If 
								you wish to change this value, click into the 
								Hits required for spam input box and type in the 
								new value.</li>
								<li>Click Set to save the changes.</li>
							</ol>
						
						<p>You can choose what to do with the mail recognized as 
						spam: you can choose to either delete it, or to mark it 
						as spam and leave it in the mailbox.</p>
						
							<ol>
								<li>Select the Delete radio-button to delete 
								mail recognized as spam, or the Mark as spam and 
								store in mailbox radio-button to leave the mail 
								marked as spam in your mailbox;</li>
								<li>Click Set to save the changes.</li>
							</ol>
						
						<p>If you decide to leave the mail recognized as spam in 
						your mailbox such messages will be marked 
						correspondingly so that they can be easily visually 
						identified. In particular, a special string is added to 
						the subject of the message (e.g., by default the string 
						*****SPAM***** will be added to the spam messages 
						subjects). You can change this string (or tag) to 
						whatever you like, or even to disable this option.</p>
						
							<ol>
								<li>In order to activate/deactivate the option 
								of modifying the spam messages subject, check 
								the Modify spam mail subject;</li>
								<li>To change the text of the string, click into 
								the input field and enter the new text;</li>
								<li>Click Set to save the changes.</li>
							</ol>
						
						<p>Black list is a list of E-mail addresses, which are 
						automatically considered as sending unsolicited mail – 
						spam. Therefore, all messages coming from the E-mail 
						addresses that match those specified in the black list 
						will automatically be marked as spam.</p>
						<p>You can add to the black list either exact E-mail 
						addresses or patters, using wildcards (‘*’, e.g.: entry 
						‘*@spammers.online.com will cause all messages coming 
						from the domain spammers.online.com be marked as spam, 
						regardless of what the exact mail name is).</p>
						
							<ol>
								<li>Enter the E-mail address or pattern into the 
								Email pattern input field;</li>
								<li>Click Add to add the new entry to the black 
								list, the new entry will appear in the user’s 
								black list section.</li>
							</ol>
						
						<p>The Administrator’s black list section contains the 
						server wide black list entries that were added by the 
						Administrator. If you chose to use the server wide 
						filtering settings (the Use server wide settings 
						checkbox checked) you may wish to edit this section by 
						removing unnecessary entries. To do that, just select 
						the Administrator’s black list entry and click Remove.</p>
						<p>White list contains E-mail addresses, which are 
						automatically considered as trustworthy. Therefore, all 
						messages coming from the E-mail addresses that match 
						those specified in the white list will never be marked 
						as spam.</p>
						<p>You can add to the white list either exact E-mail 
						addresses or patters, using wildcards (‘*’, e.g.: entry 
						‘*@your-company.com will cause all messages coming from 
						the domain your-company.com not be marked as spam, 
						regardless of the content of a message).</p>
						
							<ol>
								<li>Enter the E-mail address or pattern into the 
								Email pattern input field;</li>
								<li>Click Add to add the new entry to the white 
								list, the new entry will appear in the user’s 
								white list section.</li>
							</ol>
						
						<p>The Administrator’s white list section contains the 
						server wide white list entries that were added by the 
						Administrator. If you chose to use the server wide 
						filtering settings (the Use server wide settings 
						checkbox checked) you may wish to edit this section by 
						removing unnecessary entries. To do that, just select 
						the Administrator’s white list entry and click Remove.</p>
						<p>You can train your mail filters on actual messages 
						you receive. Click the Training icon in the Tools group 
						to access the Spam filter training page. The headers for 
						all mail that comes to your mailbox will be listed here. 
						Each such header you can select to mark as spam, ham 
						(good mail) or forget.</p>
						<p>&nbsp;</p>
						
							<ul>
								<li>Marking a header as spam will result in 
								recognition of same or similar mail as spam;</li>
								<li>Marking a header as ham will result in 
								recognition of same or similar mail as not spam;</li>
								<li>Option forget clears the database of any 
								rules (spam or ham) previously set for this 
								header.</li>
							</ul>
						
						<p>&nbsp;</p>
						<p>Once you select one of the options, appropriate rules 
						will be added to the spam filter database, which will 
						allow in the future to recognize messages similar to the 
						ones it was trained on, and make decisions regarding 
						whether a message should be considered spam or not based 
						on that.</p>
						<p>Use the Clear button if you want to clear the spam 
						filter’s database.</p>
						<p>Click OK to save the changes and return to the Spam 
						Filter page.</p>
						<p>This concludes setting up the user level spam mail 
						filter. All the incoming mail for the selected mail name 
						will be processed according to these settings.</p>
					
						
							
								<h3>Enabling Antivirus Filtering For Mailboxes</h3>
							
						
						<p>For a user’s mailbox you can enable the antivirus 
						scanner to work in one of the following modes: checking 
						incoming and outgoing mail, checking outgoing mail only, 
						and checking only incoming mail.</p>
						<p>When antivirus scanning is enabled, all e-mail 
						messages containing viruses are intercepted.</p>
						<p>To enable antivirus scanning for a mailbox, follow these steps:</p>
							<ol>
								<li>On the mail name properties page click &nbsp;&nbsp;
                <img alt="" src={"/images/articles/btn_mail-drweb_bg.gif"} width="32" height="32" />
                &nbsp;&nbsp;Dr.Web. The antivirus preferences page will appear</li>				
								<li>Select a required scanning mode and click OK.</li>
              </ol>
        </Row>
        <Row>
          <h3>Performing Group Operations on Mail Names</h3>
          <p>In cases when you need to introduce certain similar 
						changes to several mail name accounts, you can use the 
						Group Operations function, made available to simplify 
						administration of multiple accounts. Using this feature 
						you can, for instance, select a number of mail names, 
						and enable antivirus protection for all of them - all 
						that within a single operation, without having to select 
						each mail name independently and edit its settings.</p>
						<p>To perform group operations on mail names, follow 
						these steps:</p>

					<ol>
								<li>In the list of mail names, select the mail 
								names, whose accounts you wish to modify by 
								checking the corresponding checkboxes.</li>
								<li>Click the  &nbsp;&nbsp;
								<img alt="" src={"/images/articles/btn_global-changes_bg.gif"} width="32" height="32" />
                &nbsp;&nbsp;&nbsp;Group Operations icon. The Group Operations page will appear.</li>
								<li>To enable a specific mail service, select an 
								appropriate radio-button in the Enable column.
                <br />
								To Disable a service, select the radio button in 
								the Disable column.
								<br />
                Use the Do not change option to leave as is.</li>
								<li>Click OK to apply the changes to the selected mail names.</li>
							</ol>
        </Row>
      </Container>
    </>
  );
}

export default ManagingEmailAccount;


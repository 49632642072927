import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";

export default function TermsAndConditions() {
    return (
      <main>
        <Header 
            siteTitle="Hostitwise Inc. Terms & Conditions" 
            siteSubTitle="we never share any data with anyone"   
        />
        <h2>HOSTITWISE.COM Legal Agreements and Policies</h2>
         
        <h3>Additional Hosting Terms & Conditions</h3>
        <p>This Web Hosting Services Agreement ("Agreement") is a valid legal agreement between hostitwise (also referred to as hostitwise.com), an Illinois partnership, and the party set forth in the related order form (“Customer” or “you” the individual or single entity ordering), and applies to the purchase of all services ordered by Customer on the Order Form (also referred to as the "Services").</p>
        <blockquote>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE COMPLETING THE AGREEMENT PROCESS  BY CLICKING ON THE BUTTON ON THE ORDER FORM YOU CREATE A CONTRACT BETWEEN YOU AND HOSTITWISE, CONSISTING OF THE ORDER (OR ORDER FORM), THE APPLICABLE SERVICE DESCRIPTION AND THIS USER AGREEMENT AND YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT.</blockquote>
        <h3>THE FOLLOWING ARE UNACCEPTABLE USES:</h3>
        <ul>
          <li>using a non-existent email return address</li>
          <li>sending unsolicited advertising to numerous email addresses or newsgroups and/or generating a significantly higher volume of outgoing email than a normal user),</li>
          <li>trolling (posting outrageous messages to generate numerous responses),</li>
          <li>mailbombing (sending multiple messages without significant new content to the same user),</li>
          <li>subscribing someone else to a mailing list without that person's permission,</li>
          <li>cross-posting articles to an excessive number of newsgroups,</li>
          <li>attempting without authorization to enter into a secured computer system.</li>
          <li>Hostitwise hosting reserves the right to determine what constitutes net abuse.</li>
        </ul>
        <h4>Illegality in any form</h4>
        <p>including but not limited to activities such as:</p>
        <ul>
          <li>unauthorized distribution or copying of copyrighted software,</li>
          <li>violation of U.S. export restrictions,</li>
          <li>harassment,</li>
          <li>fraud,</li>
          <li>trafficking in obscene material,</li>
          <li>drug dealing,</li>
          <li>and other illegal activities.</li>
        </ul>
        <h4>Spamming</h4>
          <p>We have a ZERO toleration policy for all spammers! Spamming is completely prohibited on all sites hosted by hoastitwise.com.Users who spam via E-mail, newsgroups, etc. are not allowed on our web servers.
            Spamming is the “distribution of unsolicited e-mail to other users or user groups that did not specifically ask for the information.
              It doesn't matter if you use our mail servers to spam or if your hire a company to send those mailings for you. After the first complaint, in a bulk e-mail situation from anyone, you will be removed and your account will be cancelled.</p>
        <h4>Tortious conduct</h4>
          <p>Including but not limited to: posting of insulting, scandalous, or private information about a 
              person without their consent,intentionally inflicting emotional distress, or violating trademarks, 
              copyrights, or other intellectual property rights.</p>

        <h4>Pornography</h4>
          <p>No Adult contents, pornography, Warez, Freeware, eggdrop, shareware,  Pirated software, mp3 archives, 
              rom archives, cracking/hacking tools. Any material of this nature or links to material of 
              this nature will cause the account to be cancelled without financial refund.</p>

        <h4>Discrimination</h4>
        <p>Any web site material that is intentionally offensive to racial minorities is not permitted. 
              Sites that are found to contain such offensive content will be terminated without refund.</p>

        <h3>SUPPORT AND LIABILITIES</h3>
          <h4>Technical Support</h4>
          <p>Hostitwise.com does not support any of the open source applications, scripts, or programs that are offered on our hosting servers (such as osCommerce, phpBB, and phpDig, etc…). hostitwise.com does offer on-line documentation and instructions on using the web site and uploading customer’s files to the server without any support on the usage of these utilities. 
              Hostitwise does offer any support for all cgi and other scripts uploaded by the customer to our servers.</p>

          <h4>Hostitwise services</h4>
            <p>Hostitwise.com offer web hosting services that are bound to the customer’s requirements stated in the order transaction.  As stated in the order forms each account comes with a predefined amount of bandwidth transfer per month. In the event the customer goes over the bandwidth limit, hostitwise is not responsible for all traffic that exceeded the contract limitations.  Hostitwise reserves the right to notify the customer in such an event but not liable for loosed traffic.
               (all accounts are automated and might be automatically suspended by the server utilities).</p>
          <h4>Loose of data</h4>
            <p>Hostitwise is not responsible for the loose of data on all our servers.  And it’s the customers responsibilities to backup and maintain his data including but not limited to scripts, cgi, database, programs, html files, json, xml 
              files or any electronic file of any form.</p>
          
          <h4>Account Contents</h4>
          <p>Customer is solely responsible for the quality, performance and all other aspects of the Customer 
            Content and the goods or services provided through the Customer Web site.</p>

          <h4>Interruption of Services</h4>
            <p>Hostitwise reserves the right to maintain its servers and applications, thus an interruption 
              of service might occur, in that event hostitwise is not responsible for any lost revenue for 
              our clients as a result of this interruption.  Hostitwise is also not responsible for any 
              occasions that might cause our servers to go down.</p>  

            <p>Hostitwise offers NO guarantee of continuous service, service at any particular time, 
                or integrity of data stored or transmitted via its system or via the Internet. 
                Hostitwise will not be liable for the unintentional disclosure of, or corruption or removal of,
                 date transmitted or received or stored on its system. hostitwise shall not be liable to 
                 Customer or any of its customers for any claims or damages which may be suffered by Customer 
                 or its customers, including, but not limited to, losses or damages of any and every nature, 
                 resulting from the loss of data, inability to access Internet, or inability to transmit or 
                 receive information, caused by, or resulting from, delays, non deliveries, or service 
                 interruptions whether or not caused by the fault or negligence of hostitwise.</p>
          
          <h3>REFUND & CHARGING POLICY</h3>
            <h4>Refunds will be granted for the following scenarios:</h4>
            <ul>
	            <li>If your 12 month hosting account has passed the original anniversary signup date and you have failed to provide proper hostitwise, but recently noticed a charge on your credit card or simply do not want your hosting account any longer, you may email us within 30 days of your reoccurring charges and we will credit you back funds minus 1 month hosting at our monthly and an early termination fee of $25.00.</li>
	            <li>All new accounts have a "2 week no questions asked" refund from the original signup date.</li>
            </ul>
            <h4>Refunds will not be granted for the following reasons.</h4>
              <ul>
                <li>Client does not know how to write CGI or other scripting languages</li>
                <li>Client decides they no longer want service in the middle of their original subscription term. All accounts signed up will be liable for the billing cycle agreed upon.</li>
                <li>Client's site is cancelled because of non payment but then re-established again by clients request</li>
                <li>Accounts paying monthly or quarterly are not eligible for refunds.</li>
                <li>Secure server certificates are non-refundable.</li>
                <li>Software installations as well as web design performed by Hostitwise are non-refundable.</li>
                <li>Domain Name Registration, Transfer fees are non-refundable.</li>
              </ul>
            <h4>Account Renew</h4>
              <p>The Agreement shall be as set forth in the Order Form.  The Initial Term shall begin upon commencement of the Services to Customer. After the Initial Term, this Agreement shall automatically renew. Additionally after the initial term, you acknowledge, agree and authorize hostitwise to automatically bill and/or charge on your credit card for successive terms of equal 
                length as the initial term, unless terminated or cancelled by either party as provided in this document</p>
            
            <h3>TERM; TERMINATION; HOSTITWISE POLICY</h3>
              <em>This Agreement may be terminated:</em>
              <ul>
                <li>By either party by giving the other party thirty (30) days prior written notice subject to a $25.00 early hostitwise fee payable by Customer.</li>
                <li>By Hostitwise in the event of nonpayment by Customer.</li>
                <li>By Hostitwise, at any time, without notice, if, in Hostitwise sole and absolute discretion and/or judgment, Customer is in violation of any term or condition of our Agreement and related agreements, or Customer's use of the Services disrupts or, in Hostitwise’s sole and absolute discretion and/or judgment, could disrupt, Hostitwise’s business operations.</li>
              </ul>
            
            <h3>WARRANTY DISCLAIMER</h3>
              <p>Customer agrees to use all Services and any information obtained through or from hostitwise, 
                  at Customer's own risk. Customer acknowledges and agrees that hostitwise exercises no control 
                  over, and accepts no responsibility for, the content of the information passing through 
                  hostitwise‘s servers and network devices.</p>
              <p>The services provided under this agreement are provided on an as is, as available basis. 
                  None of hostitwise or affiliated corporations, or any of their respective employees, officers,
                  directors, shareholders, affiliates, agents, attorneys, suppliers, third-party information 
                  providers, merchants, licensors or the like (each, an "hostitwise person") make any 
                  warranties of any kind, either expressed or implied, including but not limited to
                  warranties of merchantability or fitness for a particular purpose, or non-infringement, 
                  for the services or any equipment hostitwise provides.</p>
              <p>No hostitwise person makes any warranties that the services will not be interrupted or error 
                  free; nor do any of them make any warranties as to the results that may be obtained from the 
                  use of the services or as to the accuracy, reliability or content of any information, services 
                  or merchandise contained in or provided through the services. Hostitwise is not liable, and 
                  expressly disclaims any liability, for the content of any data transferred either to or from 
                  customer or stored by customer or any of customer's customers via the services provided by 
                  hostitwise. No oral advice or written information given by any hostitwise person, will create 
                  a warranty; nor may you rely on any such information or advice. The terms of this section shall survive 
                  any termination of this Agreement.</p>
              
              <h3>Billing</h3>
                <h4>Billing and Charges</h4>
                <p>All hostitwises must be submitted at least 6 days before your subscription term ends. We will attempt to 
                    send a reminder email 1-2 weeks in advance as a courtesy. It is your reasonability at that point to 
                    contact us should you no longer desire service. It is the customer’s responsibility to ensure all contact 
                    information stored at hostitwise records is current and up to date. All accounts will 
                    automatically get re-billed without termination notice.</p>

                <h4>Customer agrees to the following:</h4>
                  <ul>
                    <li>Customer will pay to hostitwise the service fees for the Services in the manner set forth in the Order Form</li>
                    <li>Hostitwise may increase the Service Fees at any time on or after expiration of the Initial Term by providing ten 15 days prior written notice thereof to Customer.</li>
                    <li>Unless otherwise specified, all fees and related charges shall be due and payable within thirty 30 days after the date of the invoice. If any invoice is not paid within seven (7) days after the date of the invoice, hostitwise may charge Customer a late fee of $10.00 in addition any amounts payable to hostitwise.</li>
                    <li>If any check is returned for insufficient funds hostitwise may impose a charge of $25.00.</li>
                    <li>YOU ACKNOWLEDGE, AGREE AND AUTHORIZE HOSTITWISE TO AUTOMATICALLY BILL AND/OR CHARGE ON YOUR CREDIT CARD FOR SUCCESSIVE TERMS OF EQUAL LENGTH AS THE INITIAL TERM, UNLESS TERMINATED OR CANCELLED BY EITHER PARTY AS PROVIDED IN SECTION.</li>
                  </ul>


      </main>
    );
  }
import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Col, Container, Row } from "react-bootstrap";

//email_account.html
const Glossary = () => {
  return (
    <>
      <CustomMetas 
          metaTitle="Hosting Terms Glossary"
          metaDesc="Hosting and domain names gloassray"
          metaKeywords="hosting terminology and definitions"
         />
    <Header 
        isDomainPage={false}
        siteTitle="Transfering Files to Your Hosting Account" 
        siteSubTitle="Hosting Terminology & definitions"   
      />
      <Container >
        <Row className="border border-warning" style={{margin:"40px 0px"}}>
          <h2>Hosting & Domain Technology Definitions</h2>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>APACHE</h3></Col>
          <Col sm={10} >
            <p>Apache is an open source Web server that is distributed free. Apache runs on Unix-based operating systems (including Linux and Solaris) and Windows 95/98/NT. Apache was originally based on the NCSA server, but is now an independent product, supported by the nonprofit Apache Software Foundation.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>BROWSER</h3></Col>
          <Col sm={10} >
            <p>A browser is a software application that lets you access information on the Internet. Browsers can read HTML and send HTTP or FTP requests for services on the Internet. Browsers are usually associated with the World Wide Web portion of the Internet.</p>
          </Col>
        </Row>

        <Row className="border-bottom border-info">
          <Col sm={2}><h3>CGI</h3></Col>
          <Col sm={10} >
            <p>CGI, or the common gateway interface, provides a standardized method for Web servers to send a user request to an application and to receive information back for the user. For example, when you click on a URL link, the Web server sends the requested page to you. CGI is part of the HTTP protocol. CGI works in many different languages, and across several different platforms.</p>
          </Col>
        </Row>

        <Row className="border-bottom border-info">
          <Col sm={2}><h3>CLIENT</h3></Col>
          <Col sm={10} >
            <p>A client is a company or individual requesting services from an Internet presence provider. A client is a customer of a Web hosting company, or a user of Internet services. In hardware terminology, a client is a computer system or a software package that requests services or information from another application that resides across the network. Think of the client as your PC or workstation, through which you access programs and data across a network or the Internet, usually on a server. In very simple terms, a client is a user.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>DB WebAdmin</h3></Col>
          <Col sm={10} >
            <p>DB WebAdmin is a web-based administration tool that allows to manage a whole MySQL server as well as a single database.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>DNS</h3></Col>
          <Col sm={10} >
            <p>DNS, short for Domain Name System, is a distributed database that maps names and IP addresses for computers using the Internet. DNS is a standardized system that identifies domain name servers.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>DOMAINS</h3></Col>
          <Col sm={10} >
            <p>A domain is a virtual address on the Internet for any organization or entity. Technically, a domain is a group of networked computers (servers) that represent an organization and provide network services. However, several domains could reside on one server, in dedicated space provided by a Web hosting service. To the Internet user, a domain appears as space on one server, regardless of the implementation. Domains are identified by their familiar Internet URL (uniform resource locator) addresses. For example, www.sw-soft.com is the name of the domain where SWsoft information resides on its servers. Syntactically, a domain name is a string of names or words separated by periods. For example, a domain name such as: hello.house.neighborhood.com includes the names of:</p>
            <ul>
              <li><p><em>the host</em>: hello</p></li>
              <li><p><em>the subdomain</em>: house</p></li>
              <li><p><em>the domain</em>: neighborhood</p></li>
              <li><p><em>the organization type:</em>: com</p></li>
            </ul>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>Some top-level domain names:</h3></Col>
          <Col sm={10} >
            <p>This list of Internet top-level domains (TLD) contains top-level domains, which are those domains in the DNS root zone of the Domain Name System of the Internet. A list of the top-level domains by the Internet Assigned Numbers Authority (IANA) is maintained at the Root Zone Database.[1] IANA also oversees the approval process for new proposed top-level domains for ICANN. As of April 2021, their root domain contains 1502 top-level domains. As of March 2021, the IANA root database includes 1589 TLDs. That also includes 68 that are not assigned (revoked), 8 that are retired and 11 test domains.[1] Those are not represented in IANA's listing[2] and are not in root.zone file (root.zone file also includes one root domain).
            <a target="_blank" rel="noreferrer" href="https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains"> see full definition</a></p>
            <ul>
              <li><p><em>.arpa / ARPAnet</em>: (a Defense Department communications system that established the Internet)</p></li>
              <li><p><em>.com</em>: Commercial, for-profit organizations and businesses</p></li>
              <li><p><em>.edu</em>: Educational institutions</p></li>
              <li><p><em>.gov:</em>: Government organizations</p></li>
              <li><p><em>.int:</em>: International organizations</p></li>
              <li><p><em>.mil</em>: U.S.-based military</p></li>
              <li><p><em>.net</em>: Internet access providers</p></li>
              <li><p><em>.org</em>: Non-profit organizations</p></li>
              <li><p><em>.biz</em>: Businesses</p></li>
              <li><p><em>.info</em>: Information</p></li>
              <li><p><em>.name</em>: For registration by individuals</p></li>
              <li><p><em>full list on WikiPedia</em>:<a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains">  List of Internet Top-level Domains (TLD)</a></p></li>
            </ul>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>FTP</h3></Col>
          <Col sm={10} >
            <p>FTP, or File Transfer Protocol, is a method used to transfer files to (upload) and from (download) a remote server</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>GATEWAY</h3></Col>
          <Col sm={10} >
            <p>A gateway is a combination of hardware and software allowing dissimilar systems to communicate by filtering data through standardized protocols. Think of a gateway as a translator that allows your PC to talk with other computers on the network.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>HOST</h3></Col>
          <Col sm={10} >
            <p></p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>BROWSER</h3></Col>
          <Col sm={10} >
            <p>In a network, a host is usually a computer that stores software applications and data that may be accessed or retrieved by other users. But a host can be any addressable device on the network, not just a computer. The host provides services to other computers or users. An Internet Service Provider may also be referred to as a Web hosting company.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>BROWSER</h3></Col>
          <Col sm={10} >
            <p></p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>HTML</h3></Col>
          <Col sm={10} >
            <p>HTML, or HyperText Markup Language, is a standardized language for presenting information, graphics, and multimedia on the World Wide Web. HTML consists of hundreds of codes, tags, and symbols that define the type of information and how it should be displayed in a browser. HTML is universally understood on a wide variety of platforms.</p>
          </Col>
        </Row>
        <Row className="border-bottom border-info">
          <Col sm={2}><h3>HTTP</h3></Col>
          <Col sm={10} >
            <p>HTTP, or HyperText Transfer Protocol, is a standard for sharing World Wide Web files. HTTP lets you communicate across the Internet by carrying messages from your browser to a server.</p>
<p>
<br /><strong>
IMAP<br />
</strong>
IMAP, or Internet Message Access Protocol, is a method for receiving e-mail messages from other Internet users on your local server. IMAP lets you see message headers before choosing and viewing the entire text of mail messages. You can selectively retrieve mail messages with IMAP. Compare IMAP to the POP and SMTP mail protocols.
<br />
<br /><strong> IP ADDRESS<br /> </strong>
An IP address (Internet Protocol address) is an internal number that identifies a host on the Internet or a network. IP numbers are invisible to end users, replaced in your user interface by the more familiar domain names and URLs.
<br /><strong>
<br />
IP POOL<br />
</strong>
IP address pool is the range of available IP addresses.
<br /><br />

<strong>MAIL AUTORESPONDER</strong><br />
Mail autoresponders are automatic replies to email sent to a particular mail name. Autoresponders can include both a text message and attached files. This mail function is often used on mail accounts for individuals who are away for a certain period of time, or are unable to check their mail for any number of reasons.
<br /><br />

<strong>MAIL GROUP</strong><br />
Mail groups are used for sending e-mail to a group of people through one address rather than to each individual address. Mail groups save you time and effort in reaching several people at once; you only have to create one e-mail message to the group, rather than several identical messages to everyone.
<br /><br />

<strong>MAILMAN</strong><br />
Mailman is software to help manage email discussion lists, much like Majordomo and SmartList. Unlike most similar products, Mailman gives each mailing list a web page, and allows users to subscribe, unsubscribe, etc. over the web. Even the list manager can administer his or her list entirely from the web. Mailman also integrates most things people want to do with mailing lists, including archiving, mail-to-news gateways, integrated bounce handling, spam prevention, email-based admin commands, direct SMTP delivery (with fast bulk mailing), support for virtual domains, and more. Mailman runs on most Un*x-like systems, is compatible with most web servers and browsers, and most SMTP servers.
<br /><br />

<strong>MAIL REDIRECT</strong><br />

Mail redirects are used to forward or redirect email from one POP3 mailbox to another email address. By creating an email redirect or alias, messages are sent to a different email box without the sender needing to know the new address. Email can be redirected to an address outside the domain.
<br /><br /><strong>MySQL</strong><br />

SQL is a Structured Query Language that was created as a standardized method of defining, manipulating, and searching data in a database. It is currently the most commonly used database language. My SQL is a fast, easy-to-use, multi-user SQL database server in a standard client/server environment. MySQL handles graphics as well as text. For more information, visit http://www.mysql.com.
<br /><br /><strong>NETWORK</strong><br />

A network is a system of interconnected computers and peripheral devices (such as printers).
<br /><br /><strong>PACKET<br /></strong>

Data that is transported across the Internet is divided into small, manageable units called packets. Data packets can be sent more quickly and efficiently across a network than the full stream of data in a message or file.
<br /><br /><strong>PERL<br /></strong>

Perl is an interpreted high-level programming language. Perl is very popular among System Administrators who use it for a vast number of automation tasks. Many CGI programs are written in Perl.
<br /><br /><strong>PHP</strong><br />
PHP (originally meaning Personal Home Page) is a server-based HTML embedded scripting language that runs on multiple platforms, primarily on Linux servers. PHP accesses and manipulates data in a MySQL database, and helps you create dynamic Web pages. You write HTML and embed code in the HTML that performs a specific function. The embedded code is the PHP portion of the script, identified in the HTML by special start and stop tags. A PHP file has an extension of .php or .php3 or phtml. All PHP code is executed on a server, unlike a language such as JavaScript that is executed on the client system. click here for more information on PHP. PHP hosting accounts requires a PHP Compiler to be loaded on the server.

<br /><br /><strong>POP3</strong>
<br />
POP3, or Post Office Protocol Version 3, is a method used to receive electronic mail across the Internet, accommodating different mail software packages and systems. POP3 receives and holds all your e-mail on a server. You can then download all your messages when you connect to the mail server; you cannot selectively retrieve messages. Compare POP to the IMAP mail protocol.

<br /><br /><strong>POSTGRESQL</strong>
<br />
PostgreSQL is an open source database system, that began as an enhancement to the POSTGRES research prototype DBMS. Where POSTGRES used the PostQuel query language, PostgreSQL uses a subset of SQL.

<br /><br /><strong>PROTECTED DIRECTORY</strong>
A directory is an organized collection of files and subdirectory folders on a computer. A protected directory is one that cannot be accessed by all public users; you must have access privileges to read information in a protected directory.

<br /><br /><strong>PYTHON</strong>

Python is an interpreted high-level programming language. You can write web-based applications in Python that will run many times faster than traditional CGI and will have access to advanced features such as ability to retain database connections and other data between hits and access to Apache internals.

<br /><br /><strong>QMAIL</strong>
<br />
Qmail is a secure and highly reliable e-mail message handling system. It replaces the sendmail daemon on Unix and Linux systems. Qmail is fast and uses little memory. Users can create their own mail lists, and system administration is minimal. Qmail uses the Simple Mail Transfer Protocol (SMTP) for message exchange with other systems.

<br /><br /><strong>REBOOT</strong>

Rebooting simply means restarting a computer. You should not reboot a server that has users accessing it until you have informed the users that the server must be shut down temporarily. Sometimes, an emergency necessitates rebooting a server immediately, but it is not a recommended practice.

<br /><br /><strong>SECURE HTTP</strong>

Secure HTTP (S-HTTP or HTTPS) is an encryption method uses to protect documents on the World Wide Web. An alternative to S-HTTP is an SSL certificate (or Secure Socket Layer) that secures an entire session, not just a document or a file. S-HTTP supports several different message encryption formats, and works with any communication between clients and servers.
<br /><br /><strong>SECURITY</strong>

There are several different ways to control access to a computer or network, to protect proprietary data, and to maintain privacy. Security measures can be defined at several different levels (at the server level, on a directory, for an individual file, etc.) for optimum protection.
<br /><br /><strong>SERVER</strong>

A server is a computer system (a combination of hardware and software) that runs programs, stores files, directs traffic, and controls communications on a network or the Internet. Clients (also called users or workstations) access a server for specific information and services.
<br /><br /><strong>HARED IP</strong>
An IP address that can be used for hosting by several clients.

<br /><br /><strong>SKELETON DIRECTORY </strong>
In Plesk, this term refers to a set of directories and files that get copied into a newly created virtual host directory structure at the time the virtual host is created. It may be used to have a set of CGI scripts included with every account created in Plesk. It is very useful if you are looking to have a more informative, customized welcoming index.html page, and it is also helpful if you have anything else that needs to be included by default within the directories of the virtual host.

<br /><br /><strong>SMTP</strong><br />
SMTP, or Simple Mail Transfer Protocol, is a standard for transmitting mail messages across different computers on a TCP/IP network. SMTP can only be used when both the mail sender and receiver are ready. If the destination PC is not ready, a 'post office' must temporarily store the mail. In that case, a post office protocol such as IMAP or POP is used to retrieve the mail.

<br /><br /><strong>SSI</strong><br />
SSI stands for 'server-side includes', a type of HTML comment that directs the webserver to dynamically generate data for the Web page whenever information is requested. SSIs can also be used to execute programs and insert the results; therefore they represent a powerful tool for web developers.

<br /><br /><strong>SSL</strong><br />
SSL stands for Secure Socket Layer, and is a set of rules used for exchanging information between two computer devices using a public encryption system. SSL establishes secure communications between servers and clients. SSL provides a safe and authenticated method of handling e-commerce transactions. Only authorized users can access and read an SSL-encrypted data stream. An alternative to SSL is Secure HTTP (S-HTTP), used to encrypt World Wide Web documents (rather than securing an entire session, as does SSL).

<br /><br /><strong>SSL CERTIFICATE</strong><br />
An SSL certificate is an electronic key that encrypts transmissions between two computers on a public network, providing privacy and security to the session. Think of an SSL certificate as an electronic ID card for an individual or a computer service. An SSL certificate confirms that a message that you receive actually did come from the person identified. The certificate key is issued by a third party. SSL certificates are used for secure e-commerce communications, protecting information such as credit card numbers and personal data. You can generate an SSL certificate with a utility such as SSLeay. Then, submit it to a certificate authority such as GeoTrust, Inc (www.geotrust.com).

<br /><br /><strong>TCP</strong><br />
TCP stands for Transmission Control Protocol, and is the primary data transport protocol on the Internet. TCP transmissions are fast, reliable, and full-duplexed.

<br /><br /><strong>TCP/IP</strong><br />
Transmission Control Protocol/Internet Protocol, commonly known as TCP/IP, is a data transmission protocol that was developed by ARPA, the Advanced Research Projects Agency. ARPA is the founding organization of the Internet.

<br /><br /><strong>TELNET</strong><br />
Telnet is a method of accessing another remote computer. You can only access the other computer if you have permission to do so. Telnet differs from other protocols that simply request information from a host computer, because it actually logs you on to the remote computer as a user.

<br /><br /><strong>TOMCAT</strong><br />
Tomcat is a server solution based on the Java Platform that supports the Servlet and JSP specifications. Managed by the Apache Jakarta Project, it is developed in an open and participatory environment.

<br /><br /><strong>URL</strong><br />
A URL is a Uniform Resource Locator used to identify an organization or domain on the Internet. URLs are standardized names that are typically found on the World Wide Web portion of the Internet. URL addresses identify domains on the network. Read about Domains for more detail.

<br /><br /><strong>USER</strong><br />
Simply put, a user is a client. In hardware terminology, a client is the PC that you use to access information from other computers (usually servers) on the Internet or network.

<br /><br /><strong>WEBMAIL</strong><br />
WebMail is a Web based interface to Unix system mailboxes. It allows a user to access and administer his IMAP/POP3 mailbox via the world wide web.

<br /><br /><strong>WEB USER</strong><br />
A web user is a user account within Apache that is used to define locations for personalized web pages with individual FTP access.

<br /><br /><strong>WORKSTATION</strong><br />
A workstation is a user or client that accesses information from other computers (usually servers) on a network.
</p>
          </Col>
        </Row>
        

      </Container>
    </>
  );
}

export default Glossary;


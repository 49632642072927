import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import { Container } from "react-bootstrap";

//css

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

export default function CopyRight() {
    return (
      <Container>
        <div>
          <Header 
          siteTitle="Hostitwise Inc. Copy Right Statement" 
          siteSubTitle="we never share any data with anyone"   
          />
        </div>
        <div>
                <h2>COPYRIGHT2 NOTICE & DISCLAIMER INFORMATION</h2>
                <h3><a target="_blank" href="https://www.secureserver.net/legal-agreement?id=24668&plid=588662&prog_id=588662">Click here for our Full Legal Agreements</a></h3>
                <p>All images, design themes, js, react and other scripts, text, code, 
                and or features of this site are Copyright © 2005 by 
                Hostitwise, and hostitwise.com providers. 
                Other tools used by hostitwise are copyright by hostitwise 
                partners[2]. All other elements of the site (including, but 
                not limited to, proprietary CGI scripts, proprietary PHP 
                scripts, text, and proprietary Javascript code) are 
                Copyright &copy 2002-2003 Hostitwise. All rights reserved.
                The use of any of these elements elsewhere is forbidden 
                except with explicit written permission from hostitwise.com.
                We welcome links to our site; however we will not tolerate 
                framing or leeching of our content.
                If a third party site is found to contain any material 
                (including, but not limited to, images, CGI scripts, 
                PHP scripts, text, and Javascript code, ASP scripts 
                or any script code that is owned by hostitwise.com) for 
                which we have sole copyright, the offender will be reported 
                to the appropriate authorities and to their hosting provider.
                We will seek immediate removal of the offending site, suspension of the offender's hosting account, criminal prosecution to the fullest extent permitted by law, and, at our discretion, punitive damages in the sum of $10,000 for each 24 hour period (or part thereof) that the offending site is publicly accessible.
                It is the belief of the owner/operator that none of the text, 
                images, 
                or design features of this site are in violation of anybody's 
                rights. If you feel that we have violated your copyright, 
                please contact us.
                Some images were purchased/acquired by hostitwise.com 
                from our design partners and are copyrighted by their 
                perspective owner.</p>
         </div>
    </Container>
    );
}
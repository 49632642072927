import {  BrowserRouter as Router,  Route,  Routes }  from "react-router-dom";

import { React } from "react";
import { Container } from "react-bootstrap";

// Import My Components
import FooterNew from "./navs-aux-components/pages-heads-footers-sliders/FooterNew";
import Domains from './Domains';
import Hosting from './Hosting';
import Contacts from './Contacts';
import Home from "./Home";
import CopyRight from "./legal-components/CopyRight";
import PrivactPolicy from "./legal-components/PrivacyPolicy";
import TermsAndConditions from "./legal-components/TermsAndConditions";
import TermsAndConditionsDomains from "./legal-components/TermsAndConditionsDomains";
import ManagingEmailAccount from "./article-components/ManagingEmailAccount";
import EmailSystemIMP from  "./article-components/EmailSystemIMP";
import PhpHosting from  "./article-components/PhpHosting";
import FTPAccounts from  "./article-components/FTPAccounts";
import Glossary from  "./article-components/Glossary";
import AppHostingTools from "./article-components/AppHostingTools";
 
export default function App(props) {
  return (
    <Container>
      <Router > 
          <Routes>
              <Route path="/" exact  element={<Home />} />
              <Route path="/hosting.html" element={<Hosting />} />
              <Route path="/domain_registration.html" element={<Domains />} />
              <Route path="/contacts.html" element={<Contacts />} />
              <Route path="/copy_right.html" element={<CopyRight />} />
              <Route path="/privacy_policy.html" element={<PrivactPolicy />} />
              <Route path="/terms_conditions.html" element={<TermsAndConditions />} />
              <Route path="/terms_conditions_domain.html" element={<TermsAndConditionsDomains />} />
              <Route path="/email_account.html" element={<ManagingEmailAccount />} />
              <Route path="/mail_system.html" element={<EmailSystemIMP />} />
              <Route path="/php_hosting.html" element={<PhpHosting />} />
              <Route path="/ftp_account.html" element={<FTPAccounts />} />
              <Route path="/glossary.html" element={<Glossary />} />
              <Route path="/web-hosting-tools.html" element={<AppHostingTools />} />

            </Routes>
      </Router>
      <FooterNew />
    </Container>
  );
}
/*
  1. Add an email service: from my account at emailJS.com: service_totm9r6, using:
      SMTP Server: p3plzcpnl434613.prod.phx3.secureserver.net
      Port: 465
      My SMTP server supports SSL checked.
      id: omar@hostitwise.com 
      pwd: Om???????

  2. Create an email template: template_wfq2wvu
  3. Create an HTML form
  4. Send email with form details using EmailJS SDK
*/

import { React, useState, useRef } from 'react';
import { Form, FormGroup, Button, Col, Row } from 'react-bootstrap';

import emailjs from '@emailjs/browser';

const ContactUsForm = () => {
const [message, setMessage] = useState(''); 
const [userName, setUserName] = useState(''); 
const [userEmail, setUserEmail] = useState(''); 

const form = useRef();

const handleChange = (event) => {
    // setMessage(document.getElementById('message'));
    event.target.name==="user_name"  ? setUserName(event.target.value)
      : event.target.name==="user_email"  ? setUserEmail(event.target.value)
        : event.target.name==="message" 
            ? setMessage(event.target.value)
              : console.log("error")
  }

const resetFormSuccess = () => {
  setMessage('');
  setUserName('');
  setUserEmail('');
  document.getElementById("email-sent-div").style.display = "block";
}
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_totm9r6', 'template_wfq2wvu', form.current, 'user_ko6phmeIKmrDDuO1FzuHG')
      .then((result) => {
          console.log('Email successfully sent!')
          console.log(result.text);
          resetFormSuccess();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <Form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col md={6}>
                  <Form.Group className="form-group" >
                    <Form.Label>GET IN TOUCH</Form.Label>
                    <Form.Control 
                      name="user_name" 
                      size="lg" type="text" required placeholder="Enter Your Name"
                      className="form-control"
                      value={userName}
                      onChange={handleChange}  
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <FormGroup className="form-group">
                    <Form.Label>EMAIL ADDRESS</Form.Label>
                    <Form.Control required  size="lg" type="email" placeholder="Enter Email ADDRESS"
                        name="user_email"
                        className="form-control"
                        value={userEmail} 
                       onChange={handleChange}        
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Form.Label>Enter Message</Form.Label>
                <Form.Control as="textarea" 
                  rows={3} 
                  name="message"
                  onChange={handleChange}
                  placeholder="Enter Your Feedback Here."
                  required
                  value={message}
               />
              </FormGroup>
              <Button 
                style={{marginTop:"10px"}} 
                variant="outline-info btn-custom btn-lg" 
                type="submit" 
                value="Send">
                  SEND MESSAGE
              </Button> 
  </Form>
  
  )
}

export default ContactUsForm;
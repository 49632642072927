import { Card, Container } from "react-bootstrap";
import MainNav from "../navs/MainNav";
import DomainNameForm from "../forms/DomainNameForm";

function Header(props) {
  return (
    <Container>
      <div className="topNav">
        <MainNav isDomainPage={props.isDomainPage}/>        
      </div>
      <Card style={{marginBottom:"10px", color:"white"}} >
            <Card.Img  src="/images/site-wide/website-hosting-366.jpg" alt="Hosting Header"  />
            <Card.ImgOverlay >
                <Card.Header style={{marginTop:"2px"}} ></Card.Header>
                <Card.Body ><DomainNameForm /></Card.Body>
            </Card.ImgOverlay>
            <Card.Footer style={{ color:"black", fontWeight:"bold"}}>
                  {props.siteTitle} - {props.siteSubTitle} 
            </Card.Footer>  
      </Card>
    </Container>
  )
}

export default Header;
import { Card, Button} from 'react-bootstrap';

export default function ArticleCard(props) {
  return (
    <Card style={{backgroundColor:"#bfdae7"}}>
      <Card.Img variant="top" src={props.articleToolImg} />
      <Card.Header style={{fontWeight:"bolder", color:"#0d6efd"}}>{props.articleType}</Card.Header>
      <Card.Body>
          <Card.Title>{props.articleTitle}</Card.Title>
          <Card.Text>
              <div dangerouslySetInnerHTML={{ __html: props.articleText}} ></div>
              <br />
              <span style={{fontWeight:"bolder"}}> {props.articleSubTitle}
              </span><br />
              <div dangerouslySetInnerHTML={{ __html: props.articleSubTitleText}} ></div>
              {/* {props.subLink > 0 && <a href={props.subLink}>{props.subLinkText}.</a>} */}
          </Card.Text>
          <Button 
            variant="primary" 
            target={props.articleHrefTarget} 
            href={props.articleHref}
          >
            {props.articleHrefText}
          </Button>
      </Card.Body>
    </Card>
  )
}
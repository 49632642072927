import React from 'react';
import ReactDOM from 'react-dom';

import "./css/App.css";
import './css/index.css';
import "./css/header_new.css";

import App from './components/App';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);
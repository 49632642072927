import { Navbar, Nav, NavDropdown } from "react-bootstrap";



export default function MainNav(props) {
  return (
      <Navbar  expand="lg" style={{ fontWeight:"bold" }}>
          <Navbar.Brand   href="/">HOME</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav"  > 
            <Nav   >
              <Nav.Link href="hosting.html">Hosting</Nav.Link>
              <Nav.Link href="/domain_registration.html">Domains</Nav.Link>
              <Nav.Link href="/hosting.html" target="_blank">Webites</Nav.Link>
              <Nav.Link href="/hosting.html" target="_blank">Security</Nav.Link>
              <Nav.Link href="/hosting.html" target="_blank">MS 365 Email</Nav.Link>
              <Nav.Link href="/hosting.html" target="_blank">WordPress</Nav.Link>



              <NavDropdown  title="Contacts" id="collasible-nav-dropdown">
                <NavDropdown.Divider />
                <NavDropdown.Item disabled eventKey="4.0">HOSTITWISE CONTACTS & SUPPORT</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.1" href="/contacts.html" >Contact Us</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.2" target="_blank" href="/hosting.html">Help & Support</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.3" target="_blank" href="/hosting.html">Domains Help</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item disabled eventKey="4.4">HOSTITWISE LEGAL AGREEMENTS & POLICIES</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item eventKey="4.5" target="_blank" href="/hosting.html">HOSTITWISE.COM Legal Agreements & Policies</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.6" href="/hosting.html" >Terms & Conditions</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.7" target="_blank" href="/hosting.html">HOSTITWISE.COM - Legal agreements</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.8" target="_blank" href="/hosting.html">Privacy Policy</NavDropdown.Item>
                <NavDropdown.Item eventKey="4.9" href="/hosting.html" >Copyright&copy;&nbsp; {new Date().getFullYear()} </NavDropdown.Item>
            </NavDropdown>
            
            <Nav.Link disabled style={{marginLeft:"20px", fontWeight:"bold"}}>Account:</Nav.Link>
            <Nav.Link target="_blank" href="/hosting.html">Login</Nav.Link>
            <Nav.Link target="_blank" href="/hosting.html">New Account </Nav.Link>
            <NavDropdown style={{color:"blueviolet"}}  title="Q&A" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/email_account.html" eventKey="4.10">Hosting Mail System Configuration</NavDropdown.Item>
                <NavDropdown.Item href="/mail_system.html" eventKey="4.11">Email Setup /IMP </NavDropdown.Item>
                <NavDropdown.Item href="/php_hosting.html" eventKey="4.11">PHP Applications Hosting </NavDropdown.Item>
                <NavDropdown.Item href="/ftp_account.html" eventKey="4.11">FTP Setup</NavDropdown.Item>
                <NavDropdown.Item href="/glossary.html" eventKey="4.11">Hosting Glossary & Definitions</NavDropdown.Item>
                <NavDropdown.Item href="/web-hosting-tools.html" eventKey="4.11">Web Hosting Tools</NavDropdown.Item>
            </NavDropdown>

      </Nav>
          </Navbar.Collapse>

    </Navbar>
    
    )
}
import CustomMetas from "./navs-aux-components/pages-heads-footers-sliders/CustomMetas";
//Page Sections
import HostingCard from "./navs-aux-components/cards/hosting-plans/HostingCard";
//import ArticleTabs from "./navs-aux-components/cards/hosting-plans/ArticleTabs";
import Slider from "./navs-aux-components/pages-heads-footers-sliders/Slider";
import Header from "./navs-aux-components/pages-heads-footers-sliders/Header";

import SslCard from "./article-components/SslCard";

//css
  import '../css/HostingPlans.css';
  import '../css/articles.css';
//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card,  CardGroup, ListGroup}  from 'react-bootstrap';


export default function Home() {
  return (
    <Container>
        <CustomMetas  
          metaTitle="Managed Web Hosting | Virtual, Dedicated & Shared Web Hosting Solutions"
          metaDesc="Managed Web Hosting Services: Virtual, Dedicated and Shared Servers.
                    We offer WordPress Hosting, PHP Hosting, Blog hosting MySQL Hosting and Domain Registration with a scalable plans."
          metaKeywords="Managed Wed Hosting, Web Hosting Solutions, Shared, Virtual and Dedicated Servers, WordPress, PHP Hosting, blog hosting, MySQL Hosting"
         />
         <Header  
          siteTitle= "Managed Web Hosting Solutions & Domain Name Registration Services"
          siteSubTitle="managed hosting services with you in mind"   
        />
        <Row>
          <Col>
            <Slider page="home" />
          </Col>
        </Row>
        <Row>
          <Col >
            <h2 style={{margin:"20px 0 0 0"}}>Web Hosting Services</h2>
            <p>Our <em>Web Hosting Services</em> is hands down the most cost effective and feature 
            packed hosting service on the web. Hostitwise has the web's best hosting plans at affordable prices that 
            tailers to your needs.
            These plans are packed with value and features. 
            We offer state-of-the-art hosting plans come complete with 
            FREE 24/7 technical support, dedicated and virtual servers. 
            View our <a  href="/hosting.html">customized hosting plans.</a> for complete details and prices!. 
            Everything you need to give your Web site the reliable, 
            high-performance home it deserves! as-low-as $4.59/mo!.</p>
            <p>Often when you are creating a new website or trying to migrate your site 
            to another design, hosting provider, you need to consider and have an 
            overall plan for the type of content, frequency of change, design and 
            color schemes, style sheets, fonts, images, as well as the software 
            that will be used to build the website or content management system (CMS) 
            that you intend to use. Most web hosting service providers offer extensive 
            support for CMS systemslike Joomla, WordPress and Drupal. Web hosts generally 
            include access to the most popular CMS scripts with their packages, along 
            with an easy to install scripts (we can with one click install 
            any of these popular CMS systems with all hosting companies). 
            <a href="/application-hosting.html">read more</a></p>
          </Col>
        </Row>
        
        <Row>
          <div id="fancy-border">
            <h3 className="style-1">Coud Based Managed Web Hosting Services- Shared, VPS and Dedicated Servers</h3>
          </div>
        </Row>
        
        <CardGroup>
          <HostingCard 
              planImage = "/images/cpanel-vps.png"
              planTitle="Shop cPanel Hosting" 
              planParagraph = "Our most economical site hosting. Includes a free domain and email. Linux Hosting account Dashboard. One click WordPress installation and more. "
              planStartingAt = "2.99"
              planAd = "Shop cPanel Hosting"
              planHref = ""
          />
          <HostingCard 
              planImage = "/images/vps-hosting-301x167.jpg"
              planTitle="VPS Hosting (Virtual Server)" 
              planParagraph = "High performance SSDs for 3X the speed plus unlimited traffic. Includes weekly backups with monitoring for uptime and performance. unlimited traffic and a 99.9% uptime guarantee."
              planStartingAt = "5.99"
              planAd = "Shop VPS Hosting"
              planHref = ""
          />
          <HostingCard 
              planImage = "/images/dedicated-servers-301x167.jpg"
              planTitle="Dedicated Server" 
              planParagraph = "Full control with root access while enjoying powerful bare metal servers and flexible SSD/HDD storage configurations."
              planStartingAt = "$172.99"
              planAd = "Shop Managed Dedicated Hosting"
              planHref = ""
          />

        </CardGroup>
        <CardGroup>
          <HostingCard 
                planImage = "/images/hosting-plans/wordpress-logo2.jpg"
                planTitle="WordPress CMS Hosting" 
                planParagraph = "WordPress Hosting provides automatic setup, backups and software updates paired with 24/7, award-winning support. Get started in just a few clicks."
                planStartingAt = "9.99"
                planAd = "Shop WordPress Hosting"
                planHref = ""
            />
          <HostingCard 
                planImage = "/images/hosting-plans/business-hosting-h.jpg"
                planTitle="Web Hosting Plus - Business Hosting" 
                planParagraph = "Web Hosting Plus/Business gives you the ultra-fast speed of a Virtual Private Server with a super simple control panel — a perfect fit for those who don’t have tech skills but still need powerful hosting. All plans include one-click install, 99.9% uptime, 24/7 security monitoring, daily backups, and an easy-to-use control panel."
                planStartingAt = "30.99"
                planAd = "Shop Business Plus Hosting"
                planHref = ""
            />
        </CardGroup>
        <div id="fancy-border" >
            <h3 className="style-1" style={{margin:"40px 0 20px 0"}}>Domain Name Registration</h3>
        </div>
        
        <CardGroup>
          <Card style={{marginRight: "5px"}} border="primary"  className="mb-2 card bg-danger text-white">
              <Card.Body>             
                <>
                  {  <Card.Img
                    src="/images/domain-names/domain-dot-com-120.webp" 
                  />  }
                  <ListGroup>
                    <ListGroup.Item>Domain Name Search</ListGroup.Item>
                    <ListGroup.Item>Transfer Domain Names</ListGroup.Item>
                    <ListGroup.Item>Privacy & Protection for Domains</ListGroup.Item>
                    <ListGroup.Item>WHOIS</ListGroup.Item>
                    <ListGroup.Item>Auctions for Domain Names</ListGroup.Item>
                    <ListGroup.Item>Appraise Domain Name Value</ListGroup.Item>
                    <ListGroup.Item>Investing in Domains</ListGroup.Item>
                  </ListGroup>
                </>
                <Card.Footer style={{paddingBottom:"0px"}}>

                <Card.Img style={{width:120, marginTop:-30}}
                  src="/images/domain-names/domain-dot-com-120.webp" 
                />
                <Card.Img style={{width:120}}
                  src="/images/domain-names/domain-dot-net-120.webp" 
                />
                <Card.Img style={{width:120}}
                  src="/images/domain-names/domain-dot-xyz-120.webp" 
                />
                <Card.Img style={{width:120, marginTop:-30}}
                  src="/images/domain-names/domain-dot-law-120.webp" 
                />
                <Card.Img style={{width:120, marginTop:-100}}
                  src="/images/domain-names/domain-dot-me-120.webp" 
                />
                <Card.Img style={{width:120}}
                  src="/images/domain-names/domain-dot-live-120.webp" 
                />
                <Card.Img style={{width:120}}
                  src="/images/domain-names/domain-dot-site-120.webp" 
                />
                <p style={{textAlign:"justify"}}>At HOSTITWISE, we are proud to offer a comprehensive suite of services to support your domain name needs. Whether you are seeking to register a new domain or manage existing ones, our dedicated team is here to assist you every step of the way. Our services include domain registration for a wide array of extensions, ranging from the popular ".com" and ".net" to specialized domains such as ".tech" or ".guru." We also provide seamless domain transfer services, allowing you to consolidate and manage all your domains under one roof. Our domain management tools enable you to update DNS settings, renew registrations, and make necessary adjustments effortlessly. Additionally, we offer expert guidance on choosing the right domain extension for your specific goals and industry. With our commitment to customer satisfaction, you can trust [Your Company Name] to be your reliable partner in all things related to domain names. Contact us today and experience the convenience and expertise we bring to your online presence.</p>
              </Card.Footer>
              </Card.Body>
              
          </Card>
          <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" style={{maxHeight:"250px"}}
                src="/images/home-headphones-green.jpg" 
                alt="Dmain Name Registration"
              />
              <Card.Body>
                <Card.Title>Secure Your Domain Name</Card.Title>
                <Card.Text as="div">
                  <em>TOP DOMAINS</em>: .com, .net, .org, <br />
                  <em>TOP NEW DOMAINS</em>: .company, .solutions, .email<br />
                  <ul>
                    <li>Domain Forwarding and Masking.</li>
                    <li>Domain Locking.</li>
                    <li>DNS  Services.</li>
                    <li>Auto Renew Domains.</li>
                    <li>Security Alerts including:</li>
                      <ul>
                        <li>Change of Registration.</li>
                        <li>Registrar Changes.</li>
                        <li>Status Updates.</li>
                        <li>Renewal Reminders.</li>
                      </ul>
                    <li>Expert hosting & domain name support available 24/7/365.</li>
                  </ul>
                </Card.Text>
                <div className="d-grid gap-2">
                  <Button variant="dark" size="lg"
                      href="/hosting.html" 
                      target="_blank"  >
                      Register Your Domain
                  </Button>
                  <p style={{textAlign: "justify"}}>Domain name registration is a crucial step in establishing your unique identity on the internet. It serves as your virtual address, allowing users to find and access your website easily. When you register a domain, you essentially claim ownership of that specific web address, ensuring that no one else can use the same one. The process involves selecting a domain name that reflects your brand, business, or personal identity and checking its availability. It is advisable to choose a memorable and relevant domain name that resonates with your audience. Domain registration typically involves an annual fee, providing you with exclusive rights to use that particular web address for the specified duration. Additionally, domain registration offers the opportunity to personalize your online presence, enhancing credibility and professionalism. It's a foundational step in building your digital footprint and creating a recognizable and trustworthy brand on the vast landscape of the internet.</p>
                  <p style={{textAlign: "justify"}}>The internet is a diverse landscape with a variety of domain extensions catering to different purposes and industries. One of the most widely recognized and used domain extensions is ".com," which is commonly associated with commercial websites. ".net" originally denoted network-related entities but is now used more broadly. Other generic top-level domains (gTLDs) like ".org" (for organizations), ".edu" (for educational institutions), and ".gov" (for government entities) serve specific sectors. Country code top-level domains (ccTLDs) such as ".uk" (United Kingdom), ".ca" (Canada), and ".au" (Australia) are specific to particular countries or territories. Specialized gTLDs like ".app," ".blog," and ".tech" cater to specific industries or interests. Recently introduced domain extensions, such as ".guru" and ".io," add a creative touch to web addresses. Choosing the right domain extension is essential for aligning your website with its purpose and audience, creating a memorable online identity in the vast realm of the internet.</p>
                </div>
              </Card.Body>
            </Card>
          </CardGroup>
          <br /><br />
          <div id="fancy-border" >
            <h3 className="style-1" style={{margin:"2px 0 20px 0"}}>
              Security Certificates (SSL)
            </h3>
        </div>
        
    <SslCard />
          
      </Container>
    );
  }
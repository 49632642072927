import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Card, Button, ListGroup } from "react-bootstrap";

//SSL red Section
const SslCard = () => {
  return (
    <>
      <Card className="mb-2 card bg-danger text-white" >
            <Card.Header>Secure your website with an SSL Certificate</Card.Header>
            <Card.Body>
              <Card.Title>SSL Certificate</Card.Title>
              <Card.Text>
                Find the right SSL Certificate for your website and your business.
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>DV SSL Certificate</ListGroup.Item>
                <ListGroup.Item>EV SSL Certificate</ListGroup.Item>
                <ListGroup.Item>SAN SSL</ListGroup.Item>
                <ListGroup.Item>Wildcard SSL</ListGroup.Item>
                <ListGroup.Item>Managed SSL Service</ListGroup.Item>
              </ListGroup>
              <p style={{marginTop:40}}>
                Your website and business are an investment, that’s why Secure SSL Certificates is essential for complete protection. Transactions are done every day online; however it’s important to provide security and reliability to customers. Customers need to feel secure about purchases without having to fear credit card or identity theft. Secure SSL Certificates includes a free website Malware scanner; this is designed to fight against malicious links, viruses, and spyware focused towards your customers. Using critical tools like encrypted data to fight hijacking attacks, this helps to keep your website and business running productively and efficiently. Validate your domain name within minutes with the Standard SSL package or get your domain and company validated with Premium SSL package. SSL certificates help keep information like names, addresses, passwords, and credit card numbers safe. The standard package is more designed for websites trying to secure the website and very a domain. The premium package offers the best protection for eCommerce and extensive validation. The Secure SSL Certificates offer affordability with 90% less than other security encryptions. The packages work quickly to offer complete protection against Deer Theft, fraud, and security issues that threaten to destroy average online companies. Secure SSL Certificates offer top of the line security for all website security needs
              </p>
              <Card.Footer>
              
                <div className="d-grid gap-2">
                    <Button variant="secondary" target="_blank"href="https://hosting.hostitwise.com/products/ssl" size="lg">Secure Your Site - SSL</Button>
                </div>
              </Card.Footer>
            </Card.Body>
          </Card>
    </>
  );
}

export default SslCard;


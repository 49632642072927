import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";

export default function TermsAndConditionsDomains() {
    return (
      <main>
           <Header 
          siteTitle="Hostitwise Inc. Terms And Conditions" 
          siteSubTitle="we never share any data with anyone"   
          />
        <h2>Hosting Terms & Conditions</h2>
        <p>This Web Hosting Services Agreement ("Agreement") is a valid legal agreement between hostitwise (also referred to as hostitwise.com), an Illinois partnership, and the party set forth in the related order form (“Customer” or “you” the individual or single entity ordering), and applies to the purchase of all services ordered by Customer on the Order Form (also referred to as the "Services").</p>
        <quote>PLEASE READ THIS AGREEMENT CAREFULLY BEFORE COMPLETING THE AGREEMENT PROCESS  BY CLICKING ON THE BUTTON ON THE ORDER FORM YOU CREATE A CONTRACT BETWEEN YOU AND HOSTITWISE, CONSISTING OF THE ORDER (OR ORDER FORM), THE APPLICABLE SERVICE DESCRIPTION AND THIS USER AGREEMENT AND YOU ARE AGREEING TO BE BOUND BY THE TERMS OF THIS AGREEMENT.</quote>
        <h3>THE FOLLOWING ARE UNACCEPTABLE USES:</h3>
        <ul>
          <li>using a non-existent email return address</li>
          <li>sending unsolicited advertising to numerous email addresses or newsgroups and/or generating a significantly higher volume of outgoing email than a normal user),</li>
          <li>trolling (posting outrageous messages to generate numerous responses),</li>
          <li>mailbombing (sending multiple messages without significant new content to the same user),</li>
          <li>subscribing someone else to a mailing list without that person's permission,</li>
          <li>cross-posting articles to an excessive number of newsgroups,</li>
          <li>attempting without authorization to enter into a secured computer system.</li>
          <li>Hostitwise hosting reserves the right to determine what constitutes net abuse.</li>
        </ul>
        <h4>Illegality in any form</h4>
        <p>including but not limited to activities such as:</p>
        <ul>
          <li>unauthorized distribution or copying of copyrighted software,</li>
          <li>violation of U.S. export restrictions,</li>
          <li>harassment,</li>
          <li>fraud,</li>
          <li>trafficking in obscene material,</li>
          <li>drug dealing,</li>
          <li>and other illegal activities.</li>
        </ul>
        <h4>Spamming</h4>
          <p>We have a ZERO toleration policy for all spammers! Spamming is completely prohibited on all sites hosted by hoastitwise.com.Users who spam via E-mail, newsgroups, etc. are not allowed on our web servers.
            Spamming is the “distribution of unsolicited e-mail to other users or user groups that did not specifically ask for the information.
              It doesn't matter if you use our mail servers to spam or if your hire a company to send those mailings for you. After the first complaint, in a bulk e-mail situation from anyone, you will be removed and your account will be cancelled.</p>
        <h4>Tortious conduct</h4>
          <p>Including but not limited to: posting of insulting, scandalous, or private information about a 
              person without their consent,intentionally inflicting emotional distress, or violating trademarks, 
              copyrights, or other intellectual property rights.</p>

        <h4>Pornography</h4>
          <p>No Adult contents, pornography, Warez, Freeware, eggdrop, shareware,  Pirated software, mp3 archives, 
              rom archives, cracking/hacking tools. Any material of this nature or links to material of 
              this nature will cause the account to be cancelled without financial refund.</p>

        <h4>Discrimination</h4>
        <p>Any web site material that is intentionally offensive to racial minorities is not permitted. 
              Sites that are found to contain such offensive content will be terminated without refund.</p>

        <h3>SUPPORT AND LIABILITIES</h3>
          <h4>Technical Support</h4>
          <p>Hostitwise.com does not support any of the open source applications, scripts, or programs that are offered on our hosting servers (such as osCommerce, phpBB, and phpDig, etc…). hostitwise.com does offer on-line documentation and instructions on using the web site and uploading customer’s files to the server without any support on the usage of these utilities. 
              Hostitwise does offer any support for all cgi and other scripts uploaded by the customer to our servers.</p>

          <h4>Hostitwise services</h4>
            <p>Hostitwise.com offer web hosting services that are bound to the customer’s requirements stated in the order transaction.  As stated in the order forms each account comes with a predefined amount of bandwidth transfer per month. In the event the customer goes over the bandwidth limit, hostitwise is not responsible for all traffic that exceeded the contract limitations.  Hostitwise reserves the right to notify the customer in such an event but not liable for loosed traffic.
               (all accounts are automated and might be automatically suspended by the server utilities).</p>
          <h4>Loose of data</h4>
            <p>Hostitwise is not responsible for the loose of data on all our servers.  And it’s the customers responsibilities to backup and maintain his data including but not limited to scripts, cgi, database, programs, html files, json, xml 
              files or any electronic file of any form.</p>
          
          <h4>Account contents</h4>
          <p></p>
      </main>
    );
  }
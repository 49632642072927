import { Helmet } from 'react-helmet';

export default function CustomMetas(props)  {
  return (
    <Helmet>
        <title>{props.metaTitle}</title>
        <meta 
            name="description" 
            content={props.metaDesc} 
        />
        <meta 
            name="keywords" 
            content={props.metaKeywords} 
        />
    </Helmet>
  );
}
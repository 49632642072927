import { Container, Row, Col }  from 'react-bootstrap';

const FooterNew = () => {
  return (
    <div  className="footer-06" style={{margin:"0 12px"}}> 
        <Container className="container">
            <Row className="row">
                <Col md={3}  className="col-lg-6 order-md-last">
                    <Row className="row justify-content-end">
                        <Col md={12} className="col-lg-9 text-md-right mb-md-0 mb-4">
                            <h3 className="footer-heading">HOSTITWISE PROMISE</h3>
                            <p>At HOSTITWISE.COM, we redefine your hosting experience from inception to deployment. Our expert team manages every aspect of your hosting journey, ensuring seamless performance, unparalleled security, and unparalleled scalability. Trust us to navigate the complexities while you focus on what truly matters – growing your business. Experience the epitome of managed hosting with HOSTITWISE.COM and elevate your digital presence effortlessly.</p>
                        </Col>
                        <Col md={12} className="col-lg-9 text-md-right mb-md-0 mb-4">
                            <h3 className="footer-heading">HOSTITWISE INC.</h3>
                            <p className="copyright">Copyright&copy;&nbsp; {new Date().getFullYear()}.
                                <a  href="/copy_right.html">All rights reserved</a> 
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={9} className="col-lg-6">
                    <Row>
                        <Col md={4} className="mb-md-0 mb-4">
                            <h2 className="footer-heading">Information</h2>
                            <ul className="list-unstyled">
                                <li><a href="/" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>Our Company</a></li>
                                <li><a href="/hosting.html" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>Hosting</a></li>
                                <li><a href="/domain_registration.html" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>Domains</a></li>
                                <li><a href="/contacts.html" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>Contact Us</a></li>
                                <li><a href="/copy_right.html" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>CopyRight</a></li>
                                <li><a href="/privacy_policy.html" className="py-1 d-block"><span className="ion-ios-checkmark-circle-outline mr-2"></span>Privact Policy</a></li>
                         </ul>
                        </Col>
                        <Col md={12} className="col-lg-9 text-md-right mb-md-0 mb-4">
                            <h3 className="footer-heading">HOSTITWISE PROMISE</h3>
                            <p>
                            At Hostitwise, our commitment goes beyond just hosting; we prioritize your success. 
                                Our dedicated support team stands by 24/7, ready to assist you on your digital journey. 
                                Whether you have questions, encounter challenges, or seek guidance, we're here for you. 
                                Moreover, our hosting solutions are crafted for <em>scalability</em>, adapting effortlessly to your evolving needs. 
                                From initial setup to future expansions, experience the perfect blend of reliable support and unparalleled 
                                scalability with hostitwise.com. Let us handle the technicalities, so you can focus on achieving your online 
                                ambitions.
                            </p>
                        </Col>
                    </Row>                
                </Col>
            </Row>
        </Container>
    </div>
)
}

export default FooterNew;


import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";

export default function PrivactPolicy() {
    return (
      <main>
           <Header 
          siteTitle="Hostitwise Inc. Privacy" 
          siteSubTitle="we never share any data with anyone"   
          />
        <h2>Privact Policy Page</h2>
        <p>Your privacy is very valuable to hostitwise.com. We want to make your experience using our services 
            and on the Internet as enjoyable and rewarding as possible, and thus we have developed this privecy 
            policy to address your needs and concerns from 3 angles:</p>
            <ol>
              <li>what information this web site collects, </li>
              <li>who may receive it, </li>
              <li>and why we collect it?</li>
            </ol>
        <h3>Full Legal Agreement</h3>
        <h4><a href="https://www.secureserver.net/?pl_id=588662" target="_blank" rel="noreferrer">HOSTITWISE.COM - PRIVACY POLICY</a></h4>
      </main>
    );
  }
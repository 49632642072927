import React from 'react';
import { Col, Row } from 'react-bootstrap';

import "../css/contacts.css"
import Header from "./navs-aux-components/pages-heads-footers-sliders/Header.js";
import ContactUsForm from "./navs-aux-components/forms/ContactUsForm";

import { Container } from "react-bootstrap";
const ContactUsPageContents = () => {
  return (
    <Container>
    <div>
      <Header 
      siteTitle="Hostitwise Inc. Copy Right Statement" 
      siteSubTitle="we never share any data with anyone"   
      />
    </div>
   <div id="contact">
    <div id= "email-sent-div" style={{display:"none", backgroundColor:"green", margin: "-150px 20px 0 15px"}}>
      <h3 style={{color:"white", marginLeft:"12px", textAlign: "center"}}> 
        Thank You For Reaching Out,   your information has been send
      </h3>
    </div>
    <div className="container">
      <Row>
        <Col md={8}>
          <Row>
            <div className="section-title">
                <h2>Get In Touch</h2>
                <p>Please fill out the form below to send us an email and we will get 
                    back to you as soon as possible.</p>
            </div>

            <ContactUsForm /> 
        </Row>
        </Col>
        <Row>
            <div className="contact-item" style={{margin: 0}}>
                <h3>Contact and Support</h3>
            </div>
            <div className="contact-item" style={{margin: 0}}>
                <p>
                    <span><i className="fa fa-phone"></i> Our Promise</span> 
                    At HOSTITWISE INC, your satisfaction is our top priority, and we are here to provide 
                    comprehensive support for all your hosting needs. Whether you have inquiries about email 
                    configurations, file management, server settings, domain names, SSL certificates, or require 
                    assistance with Dedicated Servers or Virtual Private Servers (VPS), our dedicated support team is 
                    ready to assist you every step of the way. We take pride in our commitment to offering managed hosting 
                    solutions, ensuring that your online presence operates seamlessly while you focus on your core activities. 
                    
                    Don't hesitate to reach out to us for any questions or concerns you may have. Our knowledgeable and responsive 
                    support team is available around the clock to provide the assistance you need, ensuring a smooth and 
                    hassle-free hosting experience. Contact us today, 
                    and let us take care of your hosting requirements.
                </p>
            </div>
            <div className="contact-item">
                <p>
                    <span><i className="fa fa-envelope-o"></i>reach us</span> @hostitwise.com
                </p>
            </div>
            
        </Row>
      </Row>
    </div>
  </div>
  </Container>
  );
};

export default ContactUsPageContents;
import { Carousel } from 'react-bootstrap'; 

export default function Slider(props) {
  const sliderImage1 = `/images/sliders/${props.page}-slider/${props.page}-slide-1.png`;
//  const sliderImage2 = `/images/sliders/${props.page}-slider/${props.page}-slide-2.png`;
  const sliderImage3 = `/images/sliders/${props.page}-slider/${props.page}-slide-3.png`;
return (
  <Carousel fade>
    <Carousel.Item>
      <img style={{maxHeight:"500px"}}
        className="d-block w-100" alt="Web Hosting"
        src= {sliderImage1}
      />
      <Carousel.Caption>
        <h3>Web Hosting</h3>
        <p>An all-in-one package for everything you need to get clients online quickly.</p>
      </Carousel.Caption>
    </Carousel.Item>
    
    <Carousel.Item>
      <img style={{maxHeight:"500px"}}
        className="d-block w-100" alt="Domain Names"
        src={sliderImage3}
      />
      <Carousel.Caption>
        <span style={{color:"black"}}>
          <h3>Domain Names that Fits your Business</h3>
          <p><b>Get started with owning your own domain name quick and easy! Register today   Hostitwise makes it easy to register a domain name with our simple registration process</b></p>
        </span>
      </Carousel.Caption>
    </Carousel.Item>
  </Carousel>
);
}


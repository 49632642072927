import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Container, Row, ListGroup } from "react-bootstrap";

//email_account.html
const PhpHosting = () => {
  return (
    <>
      <CustomMetas 
          metaTitle="PHP Application Hosting | Affordable PHP Hosting Plans"
          metaDesc="PHP simple HTML-embedded scripting Lanaguage hosting.  All you need to run you PHP applicaiotn successffuly"
          metaKeywords="PHP Hosting, Cheap PHP Hosting"
         />
    <Header 
        isDomainPage={false}
        siteTitle="PHP Accounts Setup & Hosting" 
        siteSubTitle="PHP compilers available in every hosting plan"   
      />
      <Container>
        <Row className="border border-warning" style={{margin:"40px 0px"}}>
          <h2>PHP Hosting</h2>
          <p>If you need to run PHP application you need to ensure that your web hosting company comes with a 
              preinstalled/configured PHP environment. PHP hosting is very straight forward and most hosting 
              companies now supports along with MySQL (see MySQL hosting article). Our servers come preconfigured 
              with the latest PHP version with optional support for previous PHP versions. You choose your version 
              when you setup your hosting plan. At this time we support all none end-of-life versions for the 
              PHP hosted applicatoipm. <em><strong>PHP version 7.4 is the most used version</strong></em>.</p>
        </Row>
        <Row>
          <h2>Hosting PHP Applications</h2>
          <p>PHP originally called Personal Home Page Tools. Developed in 1994 as a simple HTML-embedded server-parsed tool for creating dynamic content in Web pages.  Since then PHP has steadily grown into a powerful web development tool and built a reputation as a powerful scripting language that was simple enough to appeal to site designers who weren't necessarily professional programmers. If you are new to PHP and want to how it works, try the introductory tutorial. Or you can download the entire manual as a huge two-part PDF file for printing and binding, or you can download the HTML version of the manual and put it on your own server. For More Resources visit the PHP Builder home page for more information on PHP tutorials, templates with PHP, manuals, content management systems, PHP scripts, classes and more.</p>
          <p>If you need to run PHP application you need to ensure that your web hosting company comes with a preinstalled/configured PHP environment.  PHP hosting is very straight forward and most hosting companies now supports along with MySQL (see MySQL hosting article).   Our servers come preconfigured with the latest PHP version with optional support for previous PHP versions.  You choose your version when you setup your hosting plan.  At this time you can select PHP 5.x (PHP 5.0, PHP 5.1, PHP 5.2) for the PHP hosting version.  PHP 6.0 is not officially releases as of the time of writing this article and will add it to out PHP hosting plans once it become available and stable.</p>
          <p>PHP can be hosted on a windows or Linux servers (among others).  for Windows our windows servers are configured with Windows IIS 7 hosting accounts and they run PHP 5 versions.  This will allow you to use the quick install feature of our PHP servers supporting PHP 5 applications such Noahs Classifieds, Geeklog, OsCommerce and many other PHP/MySQL applications.  You can integrate your own PHP 5 code into your web site as well.</p>
          <p>All Linux hosting plans come with a preconfigured PHP hosting environment and again you can choose your hosted PHP version base don your needs.  All PHP hosting accounts also come with a MySQL hosting.</p>
        </Row>
        <Row>
          <h3>What does PHP offer?</h3>
          <p>PHP proposed by Rasmus Lerdorf in 1995, the main promises of PHP is to be used as a general-purpose web scripting language to support dynamic web contents.   In general scripting languages such as PSH, ASP, PERAL and others are supported as embedded languages with html tags.  To be able to run these scripting languages you need a web server that is configured with the appropriate compiler/parser or run time. For example to be able to run PHP you need the PHP compiler installed and configured on a web server.  The output of the PHP run time environment is html code that is rendered by the browser, so the client will never see the actual PHP code rather it gets html back from the server.  As I said earlier the main goal pf PHP is to produce dynamic web pages, it does by applying the server-side scripting concept (all processing/compilation) is done at the server side.</p>
          <p>PHP parser/runtime input come from the input stream (file contains PHP code among other things) and produces output into another file called the output stream containing HTML code.  PHP 4 compiler however produces Bytecode which is consumed by the Zend Engine that in turn produces the HTML.  This platform follows the JAVA model and has improved performance and portability features over previous configuration.</p>
          <p>Since PHP is a scripting language and its code is embedded within an HTML page saved with .php extension, the PHP compiler parses code within specific delimiters and ignores anything outside these delimiters (or it sends it as is to the output stream). PHP uses the &lt;?php and ?&gt; as delimiters. The main goal of these delimiters is to separate the PHP code from other code such as HTML.</p>
        </Row>
        <Row>
          <h3>Database Support in PHP</h3>
          <p>One of the reasons often given for PHP's wide acceptance by Web developers is its strong support for databases. The latest version of PHP supports all of the following database engines (although a special configuration directive is required for each to compile this support into the resulting module):</p>
          <ListGroup horizontal style={{margin:"0px 0px 50px 50px"}}>
            <ListGroup.Item>MySQL</ListGroup.Item>
            <ListGroup.Item>Oracle </ListGroup.Item>
            <ListGroup.Item>MS SQL Server</ListGroup.Item>
            <ListGroup.Item>IBM DB2</ListGroup.Item>
            <ListGroup.Item>Ingres</ListGroup.Item>
            <ListGroup.Item>SQLite</ListGroup.Item>
            <ListGroup.Item>PostgreSQL</ListGroup.Item>
            <ListGroup.Item>ODBC</ListGroup.Item>
          </ListGroup>
        </Row>
      </Container>
    </>
  );
}

export default PhpHosting;


import React  from "react";
import {Form, Button, Row, Col } from 'react-bootstrap';

class DomainNameForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
      this.state = {toURL:'https://www.secureserver.net/products/domain-registration/find?plid=588662&domainToCheck='};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
      // this.setState({toURL: this.state.toURL.concat(this.state.value)});
      if(this.handleDomainValidation()) {
        const myHref = this.state.toURL.concat(this.state.value);
        window.open(myHref, '_blank');
        this.setState({value: ''});
      }
      this.state.value.focus();
      event.preventDefault();
    }
  
    handleDomainValidation() {
      if (/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(this.state.value)) {
        return true;
      }
      else {
          alert("Please Enter Valid Domain Name: yourDomainName.{com,org,etc.}");
          return false;
      }
    }

    render() {
      return (
        <Form   style={{ margin:"75px 0px 0px 150px" }} onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <Form.Control  style={{ marginLeft:"10px" }}  type="text" required placeholder="Enter Your DomainName"
                      value={this.state.value} 
                      onChange={this.handleChange}    
                      size="lg"  
                      id="domainForm"
                      name="domainForm"
                />
            </Col>
            <Col>
            <div className="mb-2">
              <Button style={{ margin:"0 0 0 -15px" }} variant="primary" 
                  size="lg"
                  // size={this.props.sizes} 
                  type="submit" 
                  value="Submit">
                Check Domain
              </Button> 
              </div>
            </Col>
          </Row>
        </Form>
      );
    }
}

export default DomainNameForm;
import { Card } from 'react-bootstrap';

export default function HostingCard(props) {
  return (
        <Card>
            <Card.Img variant="top" src={props.planImage} />
            <Card.Body>
                <Card.Title>{props.planTitle}</Card.Title>
                <Card.Text>
                    {props.planParagraph}
                    <br /><br />
                        {props.planAd}
                    <br /><br />
                    <span className="above-price">Starting at</span>
                    <br />
                    <span  className="amount"> {props.planStartingAt}/mo </span>
                </Card.Text>
            </Card.Body>
            <Card.Footer>
                <small style={{float: "left"}} className="text-muted">{props.planTitle} Plans:  </small>
                <span style={{float: "right"}}>
                <Card.Link  
                    variant="secondary" target="_blank" 
                    href={props.planHref}>{props.planAd}
                </Card.Link>
                </span>
            </Card.Footer>
        </Card>
    )
}


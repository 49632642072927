import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Col, Container, Row } from "react-bootstrap";

//email_account.html
const FTPAccounts = () => {
  return (
    <>
      <CustomMetas 
          metaTitle="FTP Accounts | Transfering Files to Your Hosting Account"
          metaDesc="Setup File Transfere Protocol (FTP) accounts to upload files & data to your sites"
          metaKeywords="FTP Setup, FTP Hosting"
         />
    <Header 
        isDomainPage={false}
        siteTitle="Transfering Files to Your Hosting Account" 
        siteSubTitle="use FTP to upload files to your hosting server"   
      />
      <Container>
        <Row className="border border-warning" style={{margin:"40px 0px"}}>
          <h2>FTP Accounts - Uploading Site Content</h2>
          <p>You can upload site content in any of the following ways: via FTP, using Plesk File Manager or Microsoft FrontPage. We recommend uploading via FTP, as it is the simplest way.</p>
        </Row>
        <Row>
          <h3>What is File Transfer Protocol</h3>
          <p>FTP stands for File Transfer Protocol. This is a very common method, and often the simplest way, to move files between two Internet sites. It doesn't matter where the two computers are located, how they are connected, or even whether or not they use the same operating system. For example, you can retrieve files from a computer in an Australian university that runs on Unix and download it on your personal computer that runs on Windows 98. FTP uses the same TCP/IP protocol that makes it possible for different computers around the world to access websites and e-mail that were created on different systems.</p>

          <h3>FTP Access</h3>
          <p>FTP (File Transfer Protocol) is the preferred method of uploading and downloading files to your website. It utilizes a connection to one machine over port 21 to directly upload files or get files for download. FTP can also be used to arrange files and edit them on a very basic level. You have unlimited access to your account via FTP 24 hours a day. This allows you to create and maintain your web pages on your own computer, then upload the files to your website at your leisure.</p>
          <p>FTP can be used in an internet browser. The address will be ftp://www.yourdomain.com, (replace yourdomain.com  by the domain you used to register with hostitwise.com) instead of http://. There are also many pieces of software, both free and for a charge, that act as FTP clients. WS_FTP by IPswitch, CuteFTP and Fetch are among the more popular FTP clients available.</p>
          <p><strong>NOTE:</strong> If you are using FrontPage, you should NEVER use regular FTP to upload your files. This will damage the extensions. Stick with one or the other all the time.</p>
          <p>When you are ready to publish your site, there are three pieces of information that you will need to have on hand to publish to your web space:</p>
          <ol style={{marginLeft:"40px"}}>
            <li>Your domain name or IP Address. (You will need to use your IP Address for the first couple of days while your domain name becomes effective throughout the Internet.)</li>
            <li>Your account username.</li>
            <li>Your account password.</li>
          </ol>
          <h4>Using Control Panel (Plesk) File Manager</h4>
          <ol style={{marginLeft:"40px"}}>
            <li>In the Plesk control panel, click the File Manager button on the Domain Administration page.</li>
            <li>You can create and upload files and directories only in the following directories: httpdocs, httpsdocs, private and cgi-bin. You should place files and directories that should be accessible via HTTP protocol to the httpdocs directory, and files/directories that should be transferred securely over SSL protocol to the httpsdocs directory. Place your CGI scripts into the cgi-bin directory.</li>
            <ul>
              <li>Use the Add New Directory button to create new directories.</li>
              <li>To create new files in the required directory, click Add New File, in the File creation section specify the file name, select the Use html template checkbox, if you want file manager to insert some basic html tags to the new file, and click OK. A page will open allowing you to enter the content or html-formatted source of a new file. After you are done, click OK.</li>
              <li>To upload a file from the local machine, click Add New File, specify the path to its location in the File source box (or use the Browse button to locate it), and click OK.</li>
            </ul>
          </ol>
          <h3>What is Anonymous FTP?</h3>
            <p>The method of downloading public files is called anonymous FTP. 
				        Many government and educational institutions with Internet sites 
                have established publicly accessible repositories of material 
                that can be obtained using FTP, by allowing people to log in 
                using the account name "anonymous", thus these sites are also 
                called anonymous ftp servers.</p>
            <p>Many FTP sites are protected and restricted. Those who wish to 
                access it need to enter a valid username and password. Anonymous 
                FTP is different because you don't need to identify yourself 
                before accessing files. In general, you enter the word anonymous 
                or ftp when the host prompts you for a username; you can enter 
                anything for the password, such as your e-mail address or simply 
                the word "guest". In many cases, depending on the system and its 
                settings, when you access an anonymous FTP site, you won't even 
                be prompted for your name and password. </p>
        </Row>
        <Row>
          <Col>
            <h3>FTP Tools</h3>
            <h4><a rel="noreferrer" href="https://forum.filezilla-project.org/" target="_blank">FileZilla - FileZilla Forums</a></h4>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FTPAccounts;


import React from 'react';
import { Container, Tab, Tabs }  from 'react-bootstrap';
import ArticleCard from "./ArticleCard";
import getArticles from "../../../data-components/articles.js";



function ArticleTabs({ aType }) {
  var allArticles;
  if(aType !== "all")
    allArticles = getArticles().filter(tt => tt.tabType.includes(aType));
  else
    allArticles = getArticles();

  const artTabs = allArticles.map((article, i) => {
    return ( 
    <Tab key={i} eventKey={article.tabEventKey} title={article.tabTitle}>
      <ArticleCard  
      
          articleHrefTarget={article.articleHrefTarget}
          articleTitle={article.articleTitle}
          articleHref={article.articleHref}
          articleHrefText ={article.articleHrefText}
          subLink={article.articleSubLink}
          subLinkText={article.articleSubLinkText}
          articleText={article.articleText}
          articleSubTitle={article.articleSubTitle}
          articleSubTitleText={article.articleSubTitleText}
      /> 
    </Tab>
    )
  }) 


 return (  
  <Container className="tabsContainer">
    <Tabs defaultActiveKey="Dedicated" id="uncontrolled-tab-example" className="mb-3">
        { artTabs }
    </Tabs>
</Container>
  )
}

export default ArticleTabs;

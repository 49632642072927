const edArticles = [
  {
    tabType:"dedicated hosting",
    tabEventKey:"Dedicated",
    tabTitle:"Dedicated Servers",
    articleTitle:"Dedicated Server Hosting",
    articleText:"In these pages we outline some important issues as we face them in supporting our customers with their dedicated server installation and answer some issues related to for example webmail (Horde, AtMail), Mailman, and other dedicated server issues" ,
    articleHrefTarget:"_blank",
    articleHref:"https://hosting.hostitwise.com/products/dedicated-server",
    articleHrefText:"dedicated server support",
    articleSubLink:"/dedicated-server/ds-issues.html",
    articleSubLinkText:"read more for dedicated server issue resolution",
    articleSubTitle:"Dedicated Server Lost Password",
    articleSubTitleText:"Generally speaking you will not be able to retrieve your admin password on your dedicated server control panel login as this would be very dangerous. Your admin Plesk password can be retrieved (viewed) from your Linux OS when logged in as an supper user (su) and editing the file /etc/psa/.psa.shadow",
  },
  {
    tabType:"vps hosting",
    tabEventKey:"VPS",
    tabTitle:"VPS Q&A",
    articleTitle:"VPS Hosting",
    articleText:"Get the features of our Dedicated Servers at a lower price. With Virtual Dedicated hosting from HOSTITWISE.COM, your server is shared while the accounts are isolated, So you still have full control over server space. To get started, simply choose one of our popular plans or build your own. Learn more about our Virtual Dedicated Server plans. click the link below for more.",
    articleHrefTarget:"_blank",
    articleHref:"https://hosting.hostitwise.com/products/vps",
    articleHrefText :"more about VPS hosting support",
    articleSubLink:"/hosting.html",
    articleSubTitle:"Why Virtual Dedicated Servers?",
    articleSubTitleText:"Admin access: Install and run virtually anything on the server. Manage multiple Web sites: Host multiple Web sites on one server account Versatility: A dedicated server/virtual dedicated server can be used for a wide variety of purposes, including gaming, virtual (i.e., shared) hosting, and hosting of traffic-intensive Web sites. Dedicated Server web Hosting with Plesk Control Panel.",
    articleSubLinkText:"VPS hosting plan.",
  },
  {
    tabType:"domains",
    tabEventKey: "domains",
    tabTitle: "Domains",
    articleTitle: "Domain Name Transfer Procedures",
    articleText: "Domain name transfer procedures are very easy but require special attention and planning for the transfer can save you a lot of time. Also it could yield a minimum downtime. Click this link below for detailed information on domain transfer procedures. Please note that The domain name transfer process is common for all domain name registrars and requires several email and codes.",
    articleHref: "/domain_registration.html",
    articleHrefText : "click for domain name procedures",
    articleSubLink: "/domain_registration.html",
    articleSubTitle: "How to transfer os-commerce application to a different server (dedicated server)?",
    articleSubTitleText: "In this example we are moving a hosted os-commerce from a virtual server (shared) to a dedicated server. We have tested these procedures with Plesk.",
    articleSubLinkText:""
  },
  {
    tabType:"application hosting",
    tabEventKey: "eCommerce",
    tabTitle: "e-Commerce",
    articleTitle: "e-Commerace Hosting" ,
    articleText: "Ecommerce Hosting requires special and powerful servers to enable transaction processing. We offer Business Hosting Grow plans dedicated to ecommerace application such as Magento.",
    articleHrefTarget: "_blank",
    articleHref: "https://hosting.hostitwise.com/products/business",
    articleHrefText : "more about ecommerace hosting",
    articleSubLink: "/move-oscommerace-to-new-server.html",
    articleSubTitle:"How to Transfer an e-Commerce Site?",
    articleSubTitleText: "In this example we are moving a hosted os-commerce from a virtual server (shared) to a dedicated server. We have tested these procedures with Plesk.",
    articleSubLinkText: "read server migration article" ,
  },
  {
    tabType:"application hosting",
    tabEventKey: "PHP",
    tabTitle: "PHP Hosting",
    articleTitle: "PHP Hosting",
    articleText: "PHP originally called Personal Home Page Tools. Developed in 1994 as a simple HTML-embedded server-parsed tool for creating dynamic content in Web pages. Since then PHP has steadily grown into a powerful web development tool and built a reputation as a powerful scripting language that was simple enough to appeal to site designers who weren't necessarily professional programmers. If you are new to PHP and want to how it works, try the introductory tutorial. Or you can download the entire manual as a huge two-part PDF file for printing and binding, or you can download the HTML version of the manual and put it on your own server. For More PHP Builder home page for more information on PHP tutorials, templates with PHP, manuals, content management systems, PHP scripts, classes and more.",
    articleHref: "/php_hosting.html",
    articleHrefText: "See PHP hosting articles" ,
    articleSubTitle: "Hosting PHP Applications",
    articleSubTitleText: "the main promises of PHP is to be used as a general-purpose web scripting language to support dynamic web contents. In general scripting languages such as PSH, ASP, PERAL and others are supported as embedded languages with html tags",
  },
  {
    tabType:"application hosting",
    tabEventKey: "wordPress",
    tabTitle: "WordPress & Blogs Hosting",
    articleTitle: "WordPress & Blogs Hosting",
    articleText: "Blogging is the fastest growing Internet technologies on the Web. A web blog is an electronic journal that is read or consumed by anyone and is published usually by one or more authors. Blog Hosting is enabled via Blog Scripting which allow an author of the blog to publish information (ideas, thoughts) automatically on their perspective web sites or through a blogger web site. Blog Hosting can be made easy with Hostitwise since we offer FREE, easy-to-use blogging tools (Such as WordPress) with every hosting package. Launch your own blog today for as low as $4.25/month. With hostitwise packages you can create personal blog, business blog, Family Blog, Community blog, and many more types of blogs.",
    articleHrefTarget: "_blank",
    articleHref: "https://hosting.hostitwise.com/products/wordpress",
    articleHrefText : "click for Worpress hosting requirements",
    articleSubLink: "",
    articleSubTitle: "",
    articleSubTitleText: "",
    articleSubLinkText: "",
  },
  {
    tabType:"Cloud Hosting",
    tabEventKey: "cloudHosting" ,
    tabTitle: "Cloud Hosting",
    articleTitle: "Cloud Hosting",
    articleText: "Cloud computing is the on-demand availability of computer system resources, especially data storage and computing power, without direct active management by the user. Large clouds often have functions distributed over multiple locations, each location being a data center.",
    articleHrefTarget: "_blank",
    articleHref: "https://hosting.hostitwise.com/products/business",
    articleHrefText : "click for cloud hosting benefits",
    articleSubTitle: "Network of Servers Acting as One",
    articleSubTitleText: "Main features of Cloud computing include: Flexibility, Reliability, Security, Pay-as-you-Scale, State-of-the art Data Centers, Performance, and most importently pas per usage model as opposed to fixed monthly charges",
  },
];


export default function getArticles() {
    return edArticles;
}
import CustomMetas from "./navs-aux-components/pages-heads-footers-sliders/CustomMetas";
//Page Sections
import HostingCard from "./navs-aux-components/cards/hosting-plans/HostingCard";
// import Slider from "./navs-aux-components/pages-heads-footers-sliders/Slider";
import Header from "./navs-aux-components/pages-heads-footers-sliders/Header";
import ArticleTabs from "./navs-aux-components/cards/hosting-plans/ArticleTabs";

//css
  import '../css/HostingPlans.css';
  import '../css/articles.css';
//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card,  CardGroup,ListGroup } from 'react-bootstrap';



export default function Home() {
  return (
    <Container>
        <CustomMetas 
          metaTitle="Web Hosting Plans| High Performance Hosting Servers"
          metaDesc="Web hosting plans ranging from economy to ecommerace and business hosting "
          metaKeywords="Web Hosting, Economy Hosting, VPS Hosting, Business Hosting, Dedicated Hosting"
         />
         <Header 
          siteTitle="Web Hosting Services" 
          siteSubTitle="dedicated and shared servers hosting plans"   
        />
      <div style={{backgroundColor:"rgb(59 130 246 / .5)",  padding:"20px", margin: "0 12"}}>
        <Row>
          <Col >
            <h2 style={{margin:"5px 0 0 0"}}>Web Hosting</h2>
            <p><em>Web hosting</em> plays a pivotal role in the digital landscape, serving as the foundation 
                for establishing a strong online presence. Its value lies in the reliable and secure 
                storage of website files and data, ensuring seamless accessibility for users worldwide. 
                
                A robust web hosting service contributes to faster loading times, optimizing the overall user 
                experience and positively impacting search engine rankings. Beyond mere storage, hosting providers 
                often offer essential features like scalable resources, regular backups, and advanced security measures, 
                safeguarding websites against potential threats. Additionally, web hosting facilitates the integration of 
                various applications, enabling businesses and individuals to run dynamic and interactive websites. 
                
                In essence, the value of web hosting extends beyond a mere technical necessity; it is a fundamental 
                element that shapes the performance, reliability, and success of any online venture.
            </p>
          </Col>
        </Row>
        <Row>
            <div className="mb-2">                
              <Button 
                      variant="success" 
                      size="lg" 
                      target="_blank"href="/hosting.html">Business IT Hosting
              </Button>
            </div>
          </Row>
        </div>
        <Row>
          <div id="fancy-border">
            <h3 className="style-1" style={{marginTop:"50px"}}>Let Us Host Your Valuable Site</h3>
          </div>
        </Row>
        
        <CardGroup>
        <HostingCard 
              planImage = "/images/cpanel-vps.png"
              planTitle="cPanel Hosting" 
              planParagraph = "cPanel Hosting that’s easy, reliable and lightning-fast. 1 website, 30 GB storage, Unmetered bandwidth*"
              planStartingAt = "$2.99"
              planAd = "Unlimited cPanel ..."
              planHref = "/hosting.html"
          />
          <HostingCard 
              planImage = "/images/hosting-plans/wordpress-hosting-2.jpg"
              planTitle="WordPress Hosting" 
              planParagraph = "Our most economical cPanel hosting. Includes a free domain and email. Linux Hosting account Dashboard. One click WordPress installation and more. "
              planStartingAt = "7.99"
              planAd = "1-click installation"
              planHref = "/hosting.html"
          />
          <HostingCard 
              planImage = "/images/vps-hosting-301x167.jpg"
              planTitle="Deluxe" 
              planParagraph = "Unlimited sites and unlimited storage. free domain and email. 30-day money-back guarantee. Email privacy & protection with 256-bit encryption"
              planStartingAt = "5.99"
              planAd = "1-click WordPress install"
              planHref = "/hosting.html"
          />
          <HostingCard 
              planImage = "/images/dedicated-servers-301x167.jpg"
              planTitle="Ultimate" 
              planParagraph = "Increased processing power, Unlimited websites, Unmetered bandwidth, Unlimited Storage, Unlimited Databases."
              planStartingAt = "$172.99"
              planAd = "On sale - Save 23%"
              planHref = "/hosting.html"
          />
        </CardGroup>
        
        <ArticleTabs aType="hosting"/>
        
          <br /><br />
        <div id="fancy-border" >
            <h3 className="style-1" style={{margin:"20px 0 20px 0"}}>
                All hosting plans include:
            </h3>
        </div>
        <Card className="mb-2 card bg-danger text-white" >
            <Card.Header>State-of-the-Art Secured Hosting &  Data Centers</Card.Header>
            <Card.Body>
              <Card.Title>99.9% uptime — guaranteed</Card.Title>
              <ListGroup>
                <ListGroup.Item>Expert 24/7 hosting support.</ListGroup.Item>
                <ListGroup.Item>99.9% uptime — guaranteed.</ListGroup.Item>
                <ListGroup.Item>Fast and secure hosting.</ListGroup.Item>
                <ListGroup.Item>Wildcard SSL.</ListGroup.Item>
                <ListGroup.Item>Easy-to-use control panel.</ListGroup.Item>
                <ListGroup.Item>One-Click WordPress site installation.</ListGroup.Item>

              </ListGroup>
              <Card.Footer>
                <div className="d-grid gap-2">
                    <Button variant="secondary" target="_blank"href="/hosting.html" size="lg">Secure Your Site</Button>
                </div>
              </Card.Footer>
            </Card.Body>
          </Card>

      </Container>
    );
  }
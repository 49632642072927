import CustomMetas from "./navs-aux-components/pages-heads-footers-sliders/CustomMetas";
//Page Sections
  import '../css/domains.css'

//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,  Card,  ListGroup } from 'react-bootstrap';

import Header from "./navs-aux-components/pages-heads-footers-sliders/Header";
/*
import DomainNameForm from "./navs-aux-components/forms/DomainNameForm";
import ArticleTabs from "./navs-aux-components/cards/hosting-plans/ArticleTabs";
*/

export default function Home() {
  return (
    <Container>
        <CustomMetas 
          metaTitle="Domain Name Search & Domain Name Registration"
          metaDesc="Domains include free privacy protection forever "
          metaKeywords="Domain Name Regsiteration, Domain Privacy, Free SSL with Domain"
         />
         <Header isDomainPage={true}
          siteTitle="Domain Name Search & Domain Name Registration" 
          siteSubTitle="Low Prices & Huge Selection"   
        />
        <Row>
          <Col >
            <h2 style={{margin:"20px 0 0 0"}}>Domain Names</h2>
          </Col>
        </Row>
        <div style={{backgroundColor:"green", color:"white", padding:"50px", marginBottom:"40px"}}>
          <Row>
          <p>Get started with owning your own domain name quick and easy! Register today 
                    Hostitwise makes it easy to register a domain name with our simple registration 
                    process, and a domain name costs only $11.95 per year.</p>
              <div className="mb-2">
                <Button 
                    variant="success" 
                    size="lg" 
                    target="_blank"href="#">Register Your Business Name
                </Button>
              </div>
            
          </Row>
        </div>
        <Row>
          <h3>ALL INCLUSIVE DOMAIN NAME PACKAGE</h3>
            <p>Hostitwise.com Offers More Value Added Services with each Domain Name Modify Contact Info, Registrar Lock, Auto Renew, and Update Domain Password</p>
            <p><strong>Every domain name comes with:</strong></p>
            <p><em><strong>Domain Forwarding and Masking:</strong></em> Direct any domain name you own to your website. Anyone who types 
                that domain name into their browser is taken directly to your website.</p>
            <p><em><strong>Domain Locking: </strong></em> Domain locking prevents accidental or intentional transfers of domain ownership 
                and stops anyone from redirecting your nameservers.</p>
            <p><em><strong>Total DNS Control:</strong></em> Manage your domain nameserver (DNS) records and set your email, 
                    FTP, sub-domains and website location all from one control panel.</p>
            <p><em><strong>Change of Registration: </strong></em>Assign your domain name to someone else or change the 
                            contacts for your domain online anytime.</p>
            <p><em><strong>Status Alerts: </strong></em>Monitor the status of your domain and get instant alerts if there’s 
                      been a change.</p>
            <p><em><strong>Auto Renew Protection: </strong></em>No need to watch expiration dates to make sure you renew on time! 
                Auto renew keeps your domains, hosting, website builders, and other products in your name and under 
                your control.</p>
          <h3>DOMAIN NAME CONTROL PANEL</h3>
          <p>Easy and powerful control panel allows you to manage all you domain names from one central place.</p>
          <h3>AUTO-RENEW</h3>
            <p>Don't ever let your domain expire because you simply forgot. Turn on auto-renew and we'll attempt to renew it for you a few days before expiration.</p>
          <h3>REGISTRAR LOCK</h3>
            <p>Avoid any transfer mistakes and the loss of your domain. If a domain name has "registrar lock" turned on. Transfers won't be able to go through until you turn it off.</p>
          <h3>WHAT IS NAME SERVERS</h3>
            <p>A name server is an Internet host running software capable of processing DNS requests. A popular free software name server is BIND Named, for UN*X hosts.</p>
          <h4>PRIMARY AND SECONDARY NAME SERVERS</h4>
            <p>Typically, a single name server will be configured as the primary name server for a domain. 
                For backup purposes, a number of other name servers may be configured as secondary name servers. 
                From the standpoint of DNS, there is no difference between primary and secondary name servers, 
                since the resolving algorithm simply uses a domain's NS records in the order provided. Typically, 
                the primary name server is listed first, followed by the secondary, but this is not a requirement. 
                In fact, if a group of domains is served by a set of name servers, the ordering of the name 
                servers may be mixed among the domains, to facilitate load balancing.</p>
            <p>A domain's primary name server will have a file on disk containing the RR definitions for that 
                domain. Typically, secondary name servers do not have to be known to the primary. However, 
                some sites, not wishing to publicly distribute copies of their entire domain, restrict zone 
                transfers to preconfigured hosts. Secondary name servers depend on zone transfers for their 
                operation.</p>
            <p>Typically, a secondary name server will perform a zone transfer to acquire a complete copy of 
                the primary's RR database, often saving this copy on disk. Periodically, the primary's SOA record 
                for the domain is checked for changes in its SERIAL field. Upon detecting a change, the secondary 
                performs another zone transfer to acquire the updated information. Therefore, the SERIAL 
                field in a domain's SOA record must be changed every time a change is made within the domain.</p>
            <p>The timing of secondary updates is governed by several fields in the domain's SOA record. The 
                secondary check the primary's NS record every REFRESH seconds. If one can not perform a 
                scheduled check, it retries every RETRY seconds. If a check can't be performed for EXPIRE seconds, 
                then all the secondarys records for that domain are discarded, and it begins to return errors to 
                lookup requests.</p>
        </Row>
        <Row>
          <h2>Domain Name Transfer Procedures</h2>
          <p>Here is a quick doc describing domain transfer. The domain name transfer process is common for all 
            domain name registrars and requires several email and codes and approval. 
            Before you start the actual transfer you need to ensure that:</p>
          <ol>
            <li>you have access to the domain name admin contact email address, if not then you must change 
                it to an email that you can access.</li>
            <li>Unlock the domain name at your current registrar. This can be done usually from the domain admin 
                interface (or domain panel).</li>
            <li>Request an authorization code. You can do this by clicking the request auth code link and the 
                code will be sent to the admin email on the account. Also if you are moving the hosting of 
                the domain and require DNS changes then you should do that before your transfer 
                (you can maintain the new DNS after the transfer).</li>
          </ol>
          <p>Now you are ready to initiate a transfer, after you pay for the domain transfer which will give you an additional year of registration. The registrar that you are transferring to sends an email to the administrative contact of the domain you are transferring to confirm the transfer.  Godaddy for example will send a Transaction ID and Security Code that you use to approve the transfer.  Network Solutions on the other hand will send an email with an active link to the administrator’s email. </p>
          <p>The administrator will confirm and approve the transfer via a link in the email, which notifies the losing registrar to transfer the domain to Network Solutions. 		        
		        The old registrar releases the domain to new registrar and sends an email to the administrative contact confirming that the domain has been successfully transferred. 
		        The Domain Transfer Timeline:</p>
            <p>Domain transfers should be completed within 5 days from the date of the initial transfer request. With Godaddy you can transfer the domain instantaneously when you login in to your domain administration and accept the transfer.  The administrative has up to 14 days to authorize or reject the transfer. </p>

          <h3>DNS (DOMAIN NAME SYSTEM)</h3>
          <p>DNS stands for Domain Name System. The DNS is the system by which all Internet service addresses are created, maintained, and used.</p>
          <p>Under the Domain Name System, an Internet address has four elements; a server prefix, a domain name, a domain suffix (or extension), and a country code (the only optional element).</p>
          <p>The Domain Name System, or DNS, was implemented by ARPAnet in 1984, and is managed by InterNIC (the Internet Network Information Center), based in Virginia. InterNIC, in turn, is operated by ICANN, the non-profit corporation that oversees the DNS.</p>
        <h3>TOP LEVEL DOMAIN TLD</h3>
        <p>Under the Domain Name System, an Internet address has four elements; a server prefix, a domain name, a domain suffix (or extension), and a country code (the only optional element). A TLD or top level domain, therefore, would consistently follow the naming convention of something such as "http://www.hostitwise.com".</p>
        <p>For years, there were only a few top level domains, such as .com (for commerce), .gov (for government), and .org (for organization). (You can see how the DNS simplifed domain names; if you saw .gov as part of the web site address, you knew you were dealing with a government site.)</p>

        </Row>
        
          <br /><br />
          <div id="fancy-border" >
            <h3 className="style-1" style={{margin:"20px 0 20px 0"}}>
            All plans include
            </h3>
        </div>
          <Card className="mb-2 card bg-danger text-white" >
            <Card.Header>State of the Art Hosting and Data Centers</Card.Header>
            <Card.Body>
              <Card.Title>99.9% uptime — guaranteed</Card.Title>
              <ListGroup>
                <ListGroup.Item>Expert 24/7 hosting support.</ListGroup.Item>
                <ListGroup.Item>99.9% uptime — guaranteed.</ListGroup.Item>
                <ListGroup.Item>Fast and secure hosting.</ListGroup.Item>
                <ListGroup.Item>Wildcard SSL.</ListGroup.Item>
                <ListGroup.Item>Easy-to-use control panel.</ListGroup.Item>
                <ListGroup.Item>One-Click WordPress site installation.</ListGroup.Item>

              </ListGroup>
              <Card.Footer>
                <div className="d-grid gap-2">
                    <Button variant="secondary" target="_blank"href="#" size="lg">Secure Your Site</Button>
                </div>
              </Card.Footer>
            </Card.Body>
          </Card>
      </Container>
    );
  }
import Header from "../navs-aux-components/pages-heads-footers-sliders/Header";
import CustomMetas from "../navs-aux-components/pages-heads-footers-sliders/CustomMetas";
import { Container, Row } from "react-bootstrap";

//email_account.html
const ManagingEmailAccount = () => {
  return (
    <>
      <CustomMetas 
          metaTitle="Web Mail Hosting | Managing Email Accounts with Hosting Plans"
          metaDesc="Email Setup procedures and how-to. create e-mail accounts, mail Redirects, mail Autoresponders, antivirus filtering, spam mail filter settings"
          metaKeywords="web mail hosting, Web-based productivity, messaging, project-management"
         />
    <Header 
        isDomainPage={false}
        siteTitle="Email Accounts Setup" 
        siteSubTitle="how to setup your email sytem with IMP/IMP2/IMP3?"   
      />
      <Container>
				<Row className="border border-warning" style={{margin:"40px 0px"}}>
          <h2>Email Client Setup</h2>
        </Row>
				<Row >
          <h3>What is Horde?</h3>
          <p>Horde is both a piece of software and a project. The Horde 
				Project comprises a set of Web-based productivity, messaging, 
				and project-management applications, each of which is described 
				below. The Horde Framework is a common code-base used by Horde 
				applications, including libraries and a common user interface.
        The Horde Framework doesn't do anything on its own; as a user, 
				you will always be interacting with a Horde-based application.</p>
        
        <p><font size="2">1 </font> <a href="#1"><font size="2">What is IMP</font>1</a><font size="2"><br />
				2 </font> <a href="#2"><font size="2">What do "new" and "recent" mean at the top of the IMP 
				display?</font>1</a><font size="2"><br />
				3 </font> <a href="#3"><font size="2">What does the plus sign ("+") [IMP 2] or the image of face 
				[IMP 3.0] in the message index mean?</font>1</a><font size="2"><br />
				4 </font> <a href="#4"><font size="2">In mailbox view, IMP occasionally shows the recipient, 
				instead of the sender, of the message.</font>1</a><font size="2"><br />
				5 </font> <a href="#5"><font size="2">Why do I have to "subscribe" to folders?</font>1</a><font size="2"><br />
				6 </font> <a href="#6"><font size="2">How do I get rid of deleted mail?</font>1</a><font size="2"><br />
				7 </font> <a href="#7"><font size="2">How do I send mail to more than one person?</font>1</a><font size="2"><br />
				8 </font> <a href="#8"><font size="2">How can I quickly access the entries in my Contacts List?</font>1</a><font size="2"><br />
				9 </font> <a href="#9"><font size="2">How do I store more than one email address in a Contacts 
				List entry?</font>1</a><font size="2"><br />
				10 </font> <a href="#10"><font size="2">How do I attach a file to a message I've composed?</font>1</a><font size="2"><br />
				11 </font> <a href="#11"><font size="2">How can I view a message's full headers?</font>1</a><font size="2"><br />
				12 </font> <a href="#12"><font size="2">How can I filter, forward, block, or automatically respond 
				to mail?</font>1</a><font size="2"><br />
				13 </font> <a href="#13"><font size="2">After using IMP, my non-Web mail program shows a message 
				with the subject, "DON'T DELETE THIS MESSAGE -- FOLDER INTERNAL 
				DATA".</font>1</a><font size="2"><br />
				14 </font> <a href="#14"><font size="2">After spellcheck, how do I find out the correct spelling of 
				misspelt words?</font>1</a><font size="2"><br />
				15 </font> <a href="#15"><font size="2">How can I request a return receipt or delivery 
				notification?</font>1</a><font size="2"><br />
				16 </font> <a href="#16"><font size="2">How can I have IMP notify me when new messages arrive?</font>1</a><font size="2"><br />
				17 </font> <a href="#17"><font size="2">Can I undelete messages?</font>1</a><font size="2"><br />
				18 </font> <a href="#18"><font size="2">How do I print the content of an email message?</font>1</a></p>

        </Row>
        <Row>
        <p >
					<b><a href="#/" name="1">1</a>1 What is IMP?</b><br />
				IMP is the Internet Messaging Program (formerly, among other 
				things, the IMAP webMail Program), a webmail system and a 
				component of the Horde project. IMP is the most widely-deployed 
				component of Horde.<br />
				<br />
				IMP offers most of the features users have come to expect from 
				their conventional mail programs, including attachments, 
				spell-check, address books, multiple folders, and 
				multiple-language support.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="2">1</a>2 What do &quot;new&quot; and &quot;recent&quot; mean at the top of the IMP 
				display?</b><br />
				[IMP 2] In IMP's status bar, above the mailbox index or 
				message body part of the display, &quot;new&quot; refers to messages which 
				have not been read since they arrived, while &quot;recent&quot; refers to 
				messages that have arrived since the last time you looked at the 
				mailbox's index. <br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="3">1</a>3 What does the plus sign (&quot;+&quot;) [IMP 2] or the image of a 
				face [IMP 3.x] in the message index mean?</b><br />
				A plus sign (&quot;+&quot;) [IMP 2] or image of a face [IMP 3.x] in the 
				left-hand column of an entry in the mailbox index view means 
				that that message was sent directly to you, and not only Cc'd to 
				you or sent via a mailing list. In other words, your address 
				appears in the To: header.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="4">1</a>4 In mailbox view, IMP occasionally shows the recipient, 
				instead of the sender, of the message.</b><br />
				IMP's mailbox view shows the recipient in the From column on 
				messages for which you are the sender. (In other words, since 
				you sent it, you know who the sender is, so IMP tells you who 
				you sent it to.) This is particularly useful for the sent-mail 
				and drafts folders.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="5">1</a>5 Why do I have to &quot;subscribe&quot; to folders?</b><br />
				Depending on the way your site has configured IMP, you may be 
				presented, when you click on the Folders link in IMP's menu, 
				with a list of mailboxes to which you can subscribe (and 
				unsubscribe). Since it is possible to have a very large number 
				of mail folders in your account but not actively use all of the 
				folders -- the others, for instance, being archives of old mail 
				-- IMP allows you to specify which folders you wish to be able 
				to access in IMP. Subscribing to a folder makes it appear in the 
				lists of folders to which you can copy, move, and save messages 
				and for which you can display a message index.<br />
				<br />
				If your site's IMP configuration allows you to use multiple 
				folders but does not require you to subscribe to the ones you 
				wish to access, you will always see all of your folders in IMP's 
				folder lists.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="6">1</a>6 How do I get rid of deleted mail?</b><br />
				[IMP 2] When you tell IMP to delete one one or more messages 
				(by clicking Delete in the message view, or by selecting the 
				check box to the left of the message in the mailbox index view 
				and then clicking Delete), the mail is only marked as deleted, 
				and is not immediately removed from your mailbox, giving you the 
				opportunity to decide to undelete it. If you are certain you 
				will not need the deleted message(s), click on Purge Deleted 
				(or, in older versions, Expunge) in the mailbox index view. If 
				you only wish to make IMP stop displaying the deleted messages, 
				without permanently removing them, click Hide Deleted from the 
				mailbox index view.<br />
				<br />
				[IMP 3.x] IMP 3.x can work in two modes: marking mail as 
				deleted, and moving deleted messages to a Trash folder. In the 
				first mode, it works the same as IMP 2 as described in the 
				above paragraph.<br />
				<br />
				If you are using the Trash folder feature, it acts somewhat 
				differently. When you tell IMP to delete one or more messages 
				(by clicking Delete in the message view, or by selecting the 
				check box to the left of the message in the mailbox index view 
				and then clicking Delete), the mail is moved to a Trash folder, 
				giving you the opportunity to decide to undelete it (remove it 
				from the trash) later. If you are certain you will not need the 
				deleted message(s), click on Empty Trash folder in the mailbox 
				index view. In addition, you can have IMP schedule to 
				automatically empty your trash folder at various intervals via 
				the &quot;Maintainence Operations&quot; section of Options.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="7">1</a>7 How do I send mail to more than one person?</b><br />
				To send mail to more than one person, separate their email 
				addresses in the To:, Cc:, or Bcc: header (or a combination of 
				all three) with commas (&quot;,&quot;). Do not use semicolons (&quot;;&quot;) to 
				separate lists of email addresses.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="8">1</a>8 How can I quickly access the entries in my Contacts 
				List?</b><br />
				[IMP 2] Instead of opening the Contacts List dialog, you can 
				save time when sending mail to people in your Contacts List by 
				typing their nickname in the appropriate header field in the 
				Compose window itself.<br />
				<br />
				[IMP 3.0] Instead of opening the Address book window, you can 
				save time when sending mail to people in your Address book by 
				typing their name, or part of their name, in the appropriate 
				header field in the Compose window itself and the using the 
				Compose window's Expand Names button to expand the name to the 
				full address. If multiple Address book entries match, you will 
				be given a selection list of all the matching entries from which 
				you may select the desired address.<br />
				<br />
				[IMP 3.1] In addition the the Expand Names button discussed 
				above in IMP 3.0, IMP 3.1 has an Address Book button which can 
				be used to look up names in the Turba Address Books (if 
				available).<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="9">1</a>9 How do I store more than one email address in a Contacts 
				List entry?</b><br />
				[IMP 2] Each Contacts List entry can only contain a single 
				email address.<br />
				<br />
				[IMP 3.x] Full support for multiple addresses is still being 
				implemented. But, in most cases you can store multiple addresses 
				in a single Address book entry simply by listing all the email 
				addresses in the Address book Email field separated by commas. 
				Do not use semicolons (&quot;;&quot;) to separate lists of email 
				addresses.<br />
				<br />
				Note that the number of addresses that fit in a single entry may 
				be limited.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="10">1</a>10 How do I attach a file to a message I've composed?</b><br />
				Due to the nature of Web-based mail, attaching a file to a 
				message you are about to send is slightly different than what 
				you may be accustomed to in conventional mail programs. 
				Attaching a file requires two steps. While composing the 
				message:<br />
				<br />
				1. Type the filename in the Attachment field, or press the 
				Browse button to use a dialog box to choose the file to attach.<br />
				&nbsp;Press the Attach button to attach the selected file. This may 
				take a moment as the file is copied from your computer to the 
				server on which you access IMP.<br />
				<br />
				Please note that for large files and/or slower Internet 
				connections, the process of uploading and attaching your file to 
				the email may take a while. Please be patient and do not click 
				on other things while you are waiting.</p>
				<p><br />
				<b><a href="#/" name="11">1</a>11 How can I view a message's full headers?</b><br />
				To see the entire message header, click the Message Source link 
				in the Parts section of the message view. This will show you the 
				raw mail message in the mailbox, including full headers and any 
				attachments in encoded form.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="12">1</a>12 How can I filter, forward, block, or automatically 
				respond to mail?</b><br />
				[IMP 2] All of the above functions should be done when mail 
				arrives in the mailbox, not via IMP. Speak with your 
				organization's support staff to see what facilities are 
				available.<br />
				<br />
				[IMP 3.x] All of the above functions are best done when mail 
				arrives in the mailbox, not via IMP. Speak with your 
				organization's support staff to see what facilities are 
				available for this. However, limited support for filtering and 
				blocking of messages within IMP is supported.<br />
				<br />
				If you want to filter future messages from the same person as 
				the message you are currently using, use the Blacklist link when 
				in the message view. This will add a rule to filter out that 
				sender, and take you to the Filter Rules Options page.<br />
				<br />
				For more general filtering, select the Options menu item in IMP, 
				and then select Filters from the Mail Management section.<br />
				<br />
				Note that creating a rule does not filter any messages! You must 
				click the Apply All Rules button on the Filter Rules page or the 
				&quot;Apply Filters&quot; icon next to the INBOX name in the mailbox view 
				to filter all undeleted messages according to the specified 
				rules. If your installation has persistent options, you can set 
				up filter options to apply your filter rules at login and/or 
				when the a mailbox is refreshed.<br />
				<br />
				You can also create, remove, or arrange the order of rules from 
				the Filters Rules page. Note that rules are applied once per 
				message, hence rule order is significant. Make sure you have the 
				most important rules first.<br />
				<br />
				There are also modules in CVS to interface with SpamAssassin ( 
				sam), set up vacation notices ( vacation), and set up automatic 
				forwarding ( forwards).<br />
				<br />
				There is also a configuration item in the cvs HEAD version of 
				IMP that allows one to report spammers to a local spamassassin 
				installation additionally or instead of the IMP blacklist.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="13">1</a>13 After using IMP, my non-Web mail program shows a message 
				with the subject, &quot;DON'T DELETE THIS MESSAGE -- FOLDER INTERNAL 
				DATA&quot;.</b><br />
				This is an artifact of the IMAP server that your organization 
				uses, from which IMP retrieves your messages. The message can be 
				ignored.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="14">1</a>14 After spellcheck, how do I find out the correct spelling 
				of misspelt words?</b><br />
				[IMP 2] IMP 2 does not suggest replacements for misspelt 
				words; it only identifies words which do not appear in its 
				dictionary.<br />
				<br />
				[IMP 3.x] IMP 3.x will suggest replacements for misspelt words 
				automatically.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="15">1</a>15 How can I request a return receipt or delivery 
				notification?</b><br />
				[IMP 2] IMP 2 (and much of the Internet) supports neither 
				return receipts nor delivery notification.<br />
				<br />
				[IMP 3.x] IMP 3.x does have support for return receipts. You can 
				request a return receipt by clicking the &quot;Request a Return 
				Receipt&quot; checkbox in the message composition screen.<br />
				<br />
				Whether or not you will get a confirmation back depends on many 
				factors outside of IMP's control. For example, some mail servers 
				will remove return receipt requests from mail before it reaches 
				the recipient, and some mail clients will allow the user to 
				select if return receipts should be honored. Because of these 
				and other factors, you should not depend on return receipts 
				being honored by all recipients.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="16">1</a>16 How can I have IMP notify me when new messages arrive?</b><br />
				[IMP 3.x] If you IMP installation supports persistent 
				preferences, you can configure this via the Options link in the 
				IMP menu bar. From Options select New Mail (from the &quot;Other 
				Options&quot; area), then check the checkbox labeled &quot;Display pop-up 
				notification of new mail.&quot;<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="17">1</a>17 Can I undelete messages?</b><br />
				[IMP 3.x] You can undelete messages any time up until you click 
				on the Empty Trash or Purge Deleted buttons, or until any 
				automated emptying of the trash occurs if you have set up such 
				an option. To undelete messages (marked as deleted) from the 
				inbox listing, check the checkbox to the left of the messages 
				you wish to keep, then press the Undelete link at the top or 
				bottom left of the page. When you are viewing a message marked 
				for deletion, there will be a link at the start and end of the 
				message to undelete that message.<br />
				<br />
				If you are using a Trash folder, you can undelete the messages 
				by moving them out of the Trash folder into another folder.<br />
				<a href="#top">GO TO TOP</a></p>
				<p><br />
				<b><a href="#/" name="18">1</a>18 How do I print the content of an email message?</b><br />
				[IMP x] In IMP x there is no seperate print option. You will 
				have to use the print option of your web browser.<br />
				<br />
				[IMP 3.x] When viewing a message, you can easily print the 
				contents of the message using the Print link in the menu bar 
				that appears above and below the message. You should print the 
				message this way rather than using the browser print function, 
				as the browser's print function will print the entire web page 
				rather than just the message contents.<br />
				<br />
				When you click the Prink link, the message will open in a new 
				window so you may print it. Depending on the web browser and 
				operating system you use, your browser's print dialog box may 
				automatically open for you. If it does not, pull down the 
				browser's File menu and choose Print.<br />
				<a href="#top">GO TO TOP</a><br /></p>
        </Row>
      </Container>
    </>
  );
}

export default ManagingEmailAccount;

